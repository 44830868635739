import {LineItemsStore, pQuery, StoreQuery} from "../../../ps-models/lineitems-store";
import {
    Button,
    Divider,
    DropdownProps,
    Form,
    Grid,
    Header, Image,
    Input,
    Label,
    Modal, Popup,
    Segment,
    Tab,
    Table
} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {
    buildLineItemsStorePersistence,
    clearLineItemsStoreCache
} from "../../../lineitems-store/RestLineItemsStore.persistence";
import {usePopup} from "../../../ui/popup/Popup";
import {ErrorPopup} from "../../../ui/popup/ErrorPopup";
import {Loading} from "../../../ui/Loading";
import {format} from 'date-fns';
import {useForm} from "../../../ui/UseForm";
import {LineItemsTable} from "../../../lineitems-store/LineItemsTableView";
import {
    addBomDataToStore,
    DAILY_GLOBAL_SOLAR_EXPOSURE_NAME,
    GeocodeResponse,
    getGeocode,
    hardcodedBomDataSetsByCoordinates,
    MONTHLY_MEAN_NAME,
    BomData, DAILY_MEAN_NAME, createSiteStore, editSiteStore, SiteMeta
} from "./list-of-sites-data/bomStore";
import {TimeSeriesChart} from "../../../lineitems-store/TimeSeriesChart";
import {SiteMetadataSetup} from "./ListOfSitesMetadataSetup";
import {saveSiteStore} from "../../siteStoreLoader";
import {useInstantUpdater} from "../../../generic.hooks";
import {TimeUnits} from "../../../ps-models";
import {MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING, StoreSetupType, useAssetManagementModule} from "../../index";
import {DemoTimeSeriesChart} from "./DemoTimeSeriesChart";
import {authStorage} from "../../../auth";
import {getAmProjectConfig} from "../../../ps-models";

const persistence = buildLineItemsStorePersistence();

export function ListOfSites() {
    const company = authStorage.getCompany();
    const { collection } = getAmProjectConfig(company);
    const [resultStore, setResultStore] = useState<LineItemsStore>()
    const {openPopup, closePopup} = usePopup()
    const [updateId, updateComponent] = useInstantUpdater()

    const module = useAssetManagementModule();
    const storeSetup = MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING[module.Name] || MODULE_TO_ON_SITE_STORE_LOAD_ACTION_MAPPING["DEMO"];
    if(!storeSetup){
        console.warn(`The store setup was not found for module ${module.Name}, using the one for DEMO`)
    }

    useEffect(() => {
        persistence.query(collection,
            pQuery()
                .metadataOnly()
        )
            .then(setResultStore)
            .catch(e => openPopup(<ErrorPopup onClose={closePopup} e={e}/>))
    }, [collection, updateId]);

    if (!resultStore) return <Loading/>

    const res = resultStore.query(StoreQuery.all(), {withMetadata: []})
    const sites: SiteRow[] = res.rows
        .map(row => ({
            id: row.name.value.toString(),
            text: row.name.text,
            created: format(new Date(), "EEEE, MMMM do, yyyy"),
            status: 'active',
            system_size: `${3}`,
            ppa_rate: `${0.1}`,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))

    const headers = [
        {key: 'text', title: 'Site Name'},
        {key: 'id', title: 'Site ID'},
        {key: 'created', title: 'Created'},
        {key: 'status', title: 'Status'},
    ]

    return <Segment basic>
        <Header as="h2" color="purple">List of Sites</Header>
        <List headers={headers} sites={sites} storeSetup={storeSetup} />
        <Button onClick={() => openPopup(<CreateSitePopup updateListOfSites={updateComponent} onClose={closePopup} storeSetup={storeSetup}/>)}>Add New Site</Button>
    </Segment>
}

interface SiteRow {
    id: string,
    text: string,
    created: string,
    status: string,
    system_size: string,
    ppa_rate: string,
}

function List({headers, sites, storeSetup}: { headers: {key: string, title: string}[], sites: SiteRow[], storeSetup: StoreSetupType }) {
    const {openPopup, closePopup} = usePopup()
    function editSite(site: SiteRow) {
        openPopup(<CreateSitePopup onClose={closePopup} editSite={{name: site.text, id: site.id}} storeSetup={storeSetup} />)
    }

    return <Table>
        <Table.Header>
            <Table.Row>
                {headers.map(header => <Table.HeaderCell key={header.key}>{header.title}</Table.HeaderCell>)}
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {sites.map((item, i) =>
                <Table.Row key={item.id} onClick={() => editSite(item)} style={{ cursor: "pointer" }}>
                    {headers.map((header, j) => <Table.Cell key={j}>{(item as any)[header.key]}</Table.Cell>)}
                </Table.Row>
            )}
        </Table.Body>
    </Table>
}

function CreateSitePopup({onClose, updateListOfSites, editSite, storeSetup}: {onClose: () => void, updateListOfSites?: () => void, editSite?: SiteMeta, storeSetup: StoreSetupType}) {
    function onSave() {
        onClose()
        if (updateListOfSites) updateListOfSites()
    }
    return <Modal
        open={true}
        onClose={onClose}
        size="large"
    >
        <Modal.Content>
            <ConnectWeatherData onSave={onSave} editSite={editSite} storeSetup={storeSetup} />
        </Modal.Content>
    </Modal>
}

function ConnectWeatherData({onSave, editSite, storeSetup}: {onSave: () => void, editSite?: SiteMeta, storeSetup: StoreSetupType}) {
    const [bomData, setBomData] = useState<BomData | null>(null)
    const [siteMeta, setSiteMeta] = useState<SiteMeta>(() => ({
        name: '',
        id: '',
    }))
    return <>
        {editSite ? <SiteMainProperties siteMeta={editSite} /> : <SiteMainConfiguration siteMeta={siteMeta} setSiteMeta={setSiteMeta} />}
        <Tab panes={[
            {menuItem: 'Weather Data Setup', render: () => <WeatherDataSetup
                    onSave={onSave}
                    setBomData={setBomData}
                    bomData={bomData}
                    siteMeta={editSite ?? siteMeta}
                    edit={editSite !== undefined}
                    storeSetup={storeSetup}
                />
            },
            {menuItem: 'Site Metadata Setup', render: () => <SiteMetadataSetup
                    bomData={bomData} />
            },
        ]} />
    </>
}

function WeatherDataSetup({bomData, siteMeta, onSave, setBomData, edit, storeSetup}: {bomData: BomData | null, onSave: () => void, siteMeta: SiteMeta, setBomData: (data: BomData | null) => void, edit: boolean, storeSetup: StoreSetupType}) {
    const { formData, handleChange } = useForm({
        initialState: {
            weatherDataType: 'himawari',
            timeResolution: 'daily',
            locationInputType: 'postalCode',
            specifyDataRangeFrom: '2013-01-01',
            specifyDataRangeTo: '2023-06-30',
            postalCode: '',
            coordinatesLong: '',
            coordinatesLat: '',
        }
    })
    const [postCodeCoordinates, setPostCodeCoordinates] = useState<GeocodeResponse | null>(null)
    const [error, setError] = useState<string | null>(null)
    let company = authStorage.getCompany();
    const { collection } = getAmProjectConfig(company);

    useEffect(() => {
        const postCode = formData.postalCode.trim()
        if (postCode.length === 4) {
            const controller = new AbortController();
            getGeocode(postCode, controller.signal).then(setPostCodeCoordinates)
            return () => controller.abort();
        } else {
            setPostCodeCoordinates(null)
        }
    }, [formData.postalCode]);

    function handleSelectChange(e: any, {name, value}: DropdownProps) {
        handleChange({target: {name, value: value}})
    }

    function parseCoordinates() {
        const lat = parseFloat(formData.coordinatesLat)
        const lng = parseFloat(formData.coordinatesLong)
        if (isNaN(lat) || isNaN(lng)) return null
        return {lat, lng}
    }

    const hasPostCodeCoordinates = postCodeCoordinates && postCodeCoordinates !== 'empty'
    const hasCoordinates = formData.coordinatesLat && formData.coordinatesLong && parseCoordinates()
    const hasDataRange = formData.specifyDataRangeTo && formData.specifyDataRangeFrom
    const enoughData = hasDataRange && (hasPostCodeCoordinates || hasCoordinates)

    async function getData() {
        if (formData.timeResolution !== 'daily') {
            setBomData(null)
            setError('Only daily resolution is supported at the moment.')
            return
        }
        const coordinates = hasPostCodeCoordinates ? postCodeCoordinates : parseCoordinates()!
        const coordinatesKey = `${coordinates.lat.toFixed(2)}_${coordinates.lng.toFixed(2)}`
        let bom = hardcodedBomDataSetsByCoordinates[coordinatesKey]
        let message = ''
        if (!bom) {
            message = `No data for longitude ${coordinates.lng.toFixed(2)} and latitude ${coordinates.lat.toFixed(2)}, using default data set as a fallback.`
            bom = hardcodedBomDataSetsByCoordinates[Object.keys(hardcodedBomDataSetsByCoordinates)[0]]
        }
        try {
            const store = edit ?
                await editSiteStore(collection, storeSetup, siteMeta, new Date(formData.specifyDataRangeFrom), new Date(formData.specifyDataRangeTo)) :
                createSiteStore(new Date(formData.specifyDataRangeFrom), new Date(formData.specifyDataRangeTo))
            addBomDataToStore(store, bom)
            setBomData({bom, message, store})
            setError(null)
        } catch (e) {
            console.error(e)
            setBomData(null)
            setError('Error parsing data from BOM, please try again with other coordinates.')
        }
    }

    return <>
        <Header as="h2" color="purple">Connect Weather Data</Header>

        <Grid container>
            <Grid.Row columns={2}>
                <Grid.Column width={3} verticalAlign="middle"><span>Weather Data Type</span></Grid.Column>

                <Image src="/bom.png" style={{width:"35px", marginRight: "8px"}} />

                <Form.Select
                    name="weatherDataType"
                    options={[
                        { key: 'himawari', value: 'himawari', text: 'Himawari - 8/9 Global Solar Exposure' },
                        { key: 'station', value: 'station', text: 'Weather Station Level Global Solar Exposure' },
                    ]}
                    value={formData.weatherDataType}
                    onChange={handleSelectChange}
                />

            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={3} verticalAlign="middle"><span>Data Resolution</span></Grid.Column>
                <Form.Select
                    name="timeResolution"
                    options={[
                        { key: 'hourly', value: 'hourly', text: 'Hourly' },
                        { key: 'daily', value: 'daily', text: 'Daily' },
                        { key: 'monthly', value: 'monthly', text: 'Monthly' },
                    ]}
                    value={formData.timeResolution}
                    onChange={handleSelectChange}
                />
            </Grid.Row>

            <Grid.Row columns={2}>
                <Grid.Column width={3} verticalAlign="middle"><span>Location Input Type</span></Grid.Column>
                <Form.Select
                    name="locationInputType"
                    options={[
                        { key: 'postalCode', value: 'postalCode', text: 'Postal Code' },
                        { key: 'coordinates', value: 'coordinates', text: 'Coordinates' },
                    ]}
                    value={formData.locationInputType}
                    onChange={handleSelectChange}
                />
            </Grid.Row>
            <Grid.Row columns={5}>
                <Grid.Column width={3} verticalAlign="middle"><span>Specify Data Range</span></Grid.Column>
                <Grid.Column width={1} verticalAlign="middle" textAlign={"right"}><span>From</span></Grid.Column>
                <Form.Input
                    type="date"
                    name="specifyDataRangeFrom"
                    value={formData.specifyDataRangeFrom}
                    onChange={handleChange}
                    size="mini"
                    floated={"left"}
                />
                <Grid.Column width={1} verticalAlign="middle" textAlign={"right"}><span>To</span></Grid.Column>
                <Form.Input
                    type="date"
                    name="specifyDataRangeTo"
                    value={formData.specifyDataRangeTo}
                    onChange={handleChange}
                    size="mini"
                />
            </Grid.Row>
            {formData.locationInputType === 'postalCode' && <Grid.Row columns={3}>
                <Grid.Column width={3} verticalAlign="middle"><span>Postal Code</span></Grid.Column>
                <Form.Input
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                />
                {postCodeCoordinates && <Grid.Column verticalAlign={"middle"}>
                    {postCodeCoordinates === 'empty' ?
                        <Label color="red">Invalid Postal Code</Label> :
                        <div>
                            <span style={{"margin": "0px 5px 0px 5px"}}>Coordinates:</span>
                            <span style={{"margin": "0px 5px 0px 5px"}}>{postCodeCoordinates.lng.toFixed(2)}° S</span>
                            <span style={{"margin": "0px 5px 0px 5px"}}>{postCodeCoordinates.lat.toFixed(2)}° E</span></div>}
                </Grid.Column>}
            </Grid.Row>}
            {formData.locationInputType === 'coordinates' && <Grid.Row columns={5}>
                <Grid.Column width={3} verticalAlign="middle"><span>Coordinates</span></Grid.Column>
                <Form.Input
                    name="coordinatesLat"
                    value={formData.coordinatesLat}
                    onChange={handleChange}
                    width={5}
                />
                <Grid.Column width={1} verticalAlign="middle"><span>° S</span></Grid.Column>
                <Form.Input
                    name="coordinatesLong"
                    value={formData.coordinatesLong}
                    onChange={handleChange}
                />
                <Grid.Column width={1} verticalAlign="middle"><span>° E</span></Grid.Column>
            </Grid.Row>}

            <Grid.Row>
                <Grid.Column>
                    <Button color="purple" onClick={() => getData()} disabled={!enoughData}>Get Data</Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        {error && <>
            <Divider/>
            <Label color="red">{error}</Label>
        </>}
        {bomData && <WeatherReport data={bomData} onSave={()=>{
            clearLineItemsStoreCache();
            onSave();
        }} siteMeta={siteMeta} />}
    </>
}

function WeatherReport({data, onSave, siteMeta}: {data: BomData, onSave: () => void, siteMeta: SiteMeta}) {
    const {message, store} = data
    let company = authStorage.getCompany();
    const { collection } = getAmProjectConfig(company);
    const enableSavingStore = !!(siteMeta?.id && siteMeta?.name);
    const [exposureTimeAggregation, setExposureTimeAggregation] = useState<TimeUnits>('months')

    const queryResult = store.query(StoreQuery
      .byNames([DAILY_GLOBAL_SOLAR_EXPOSURE_NAME, DAILY_MEAN_NAME, MONTHLY_MEAN_NAME])
      .withTimeIndex(store.timeIndex.withGranularity(exposureTimeAggregation as TimeUnits))
    );

    return <>
        <Divider/>
        {message && <><Label color="red">{message}</Label><Divider/></>}
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column><Header as="h2" color="purple">Solar Exposure (kWh/m2)</Header></Grid.Column>
                <Form.Select
                    name="exposureTimeAggregation"
                    defaultValue={"months"}
                    options={[
                        { key: 'days', value: 'days', text: 'Daily' },
                        { key: 'months', value: 'months', text: 'Monthly' },
                        { key: 'years', value: 'years', text: 'Yearly' },
                    ]}
                    value={exposureTimeAggregation}
                    onChange={(e, {name, value}) => {
                        setExposureTimeAggregation(value as TimeUnits)
                    }}
                />
            </Grid.Row>
        </Grid>
        <LineItemsTable queryResult={queryResult} readonly />
        <DemoTimeSeriesChart title={""} result={queryResult}/>
        {/*<Header as="h2" color="purple">Solar Exposure (this year vs historical)</Header>*/}
        {/*<Grid>*/}
        {/*    <Grid.Row></Grid.Row>*/}
        {/*    <Grid.Row></Grid.Row>*/}
        {/*    <Grid.Row><Label color="red">Please connect weather data first in the other tab.</Label></Grid.Row>*/}
        {/*    <Grid.Row></Grid.Row>*/}
        {/*    <Grid.Row></Grid.Row>*/}
        {/*</Grid>*/}

        <Popup
        trigger={
            <div
                style={{ display: 'inline-block', cursor: 'not-allowed', float: 'right', marginBottom: "2px" }}
            >
            <Button disabled={!enableSavingStore} primary onClick={()=>{
                if(siteMeta?.id && siteMeta?.name){
                    store.setId(siteMeta.id);
                    store.setName(siteMeta.name);
                    saveSiteStore(collection, store).then(()=>{
                        onSave();
                    })
                }
            }
            }>Save</Button>
            </div>
        }
        content={'Please provide a name and id in the site configuration section'}
        disabled={enableSavingStore}
        />

    </>
}

const SiteMainConfiguration = ({siteMeta, setSiteMeta}: {siteMeta: SiteMeta, setSiteMeta: (data: SiteMeta) => void})=>{
    return <Segment>
        <Header as="h2" color="purple">Site Configuration</Header>
        <Form style={{width: "70%"}}>
            <Form.Group widths='equal'>
                <Form.Input
                  fluid
                  label={'Site Id'}
                  value={siteMeta?.id}
                  onChange={(evt, data)=>{
                            setSiteMeta({...siteMeta, id: data.value})
                  }}
                />
                <Form.Input
                  fluid
                  label={'Site Name'}
                  value={siteMeta?.name}
                  onChange={(evt, data)=>{
                            setSiteMeta({...siteMeta, name: data.value})
                  }}
                />
            </Form.Group>
        </Form>
    </Segment>
}

const SiteMainProperties = ({siteMeta}: {siteMeta: SiteMeta})=>{
    return <Segment>
        <Header as="h2" color="purple">Site</Header>
        <Grid >
            <Grid.Row columns={2}>
                <Grid.Column width={2}><strong>Site Id</strong></Grid.Column>
                <Grid.Column><strong>Site Name</strong></Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column width={2}>{siteMeta.id}</Grid.Column>
                <Grid.Column>{siteMeta.name}</Grid.Column>
            </Grid.Row>
        </Grid>
    </Segment>
}

