import { Grid } from "semantic-ui-react";
import {
  buildQueryFromFilters,
  ScenarioContext,
} from "./ScenarioFilter.component";
import {
  AmProject,
  AmScenario,
  FiltersDetails,
  ProjectVersion,
} from "../../ps-types";
import { ScenarioFilters } from "../../../components/Filter/Filter.component";
import { useDashboardService } from "../builder/DashboardConfigService";
import { ProjectVersionSelection } from "../projects/ProjectVersionSelection";
import { useEffect, useState } from "react";

interface AmScenarioFilterSectionProps {
  setScenarioTitle: (string: string) => void;
  setSelectedContextInternal: (arg: ScenarioContext) => void;
  filtersMap: AmScenario["filtersMap"];
  paramsMap: AmScenario["paramsMap"];
  setClearButtonDisabled: (bool: boolean) => void;
  projects: AmProject[];
}

export function AmScenarioFilterSection({
  setSelectedContextInternal,
  setScenarioTitle,
  filtersMap,
  paramsMap,
  setClearButtonDisabled,
  projects,
}: AmScenarioFilterSectionProps) {
  const { getService } = useDashboardService();
  const dService = getService();

  const [selectedProjectAndVersions, setSelectedProjectAndVersion] = useState<
    ProjectVersion[]
  >([]);

  const handleChangeScenario = (value: ProjectVersion[]) => {
    const storeIds = value.map((p) => `p:${p.projectId}:${p.versionId}`);
    const filterDetails: FiltersDetails = {
      filtersMap: (filtersMap as AmScenario["filtersMap"]) || {},
      initialFiltersMap:
        dService.getGlobalContext()?.filterDetails?.initialFiltersMap,
      filterQuery: buildQueryFromFilters(filtersMap),
    };
    setSelectedContextInternal({ storeIds, filterDetails, paramsMap });
    setScenarioTitle("Custom Selection");
    setClearButtonDisabled(false);
  };

  useEffect(() => {
    const allProjectVersions: ProjectVersion[] =
      projects &&
      projects.map((project) => ({
        projectId: project.id,
        versionId: project.defaultVersion!,
      }));

    setSelectedProjectAndVersion(allProjectVersions);
  }, [projects]);
  return (
    <>
      <Grid.Row>
        <Grid.Column style={{ display: "flex" }}>
          {dService.getConfig().isProjectVersionSelectorExposed() && (
            <ProjectVersionSelection
              projects={projects}
              selectedProjectVersions={selectedProjectAndVersions}
              handleChange={handleChangeScenario}
            />
          )}
          <ScenarioFilters />
        </Grid.Column>
      </Grid.Row>
    </>
  );
}
