import {usePSQuery} from "../../lineitems-store/LineItemsStore.hook";
import {pQuery, QueryResult, StoreQuery} from "../../ps-models/lineitems-store";
import {LineItemsTable, LineItemsTableWithFormulas} from "../../lineitems-store/LineItemsTableView";
import {Profiler, useEffect, useState} from "react";
import {buildLineItemsStorePersistence} from "../../lineitems-store/RestLineItemsStore.persistence";
import {useInstantUpdater} from "../../generic.hooks";



// @ts-ignore
function onRender(id, phase, actualDuration, baseDuration, startTime, commitTime) {
  //Nice Log
  // console.log("id", id)
  // console.log("phase", phase)
  // console.log("actualDuration", actualDuration)
}


export function LineItemsTimeSeriesTablePeformanceCheck() {

  return <Profiler id="App" onRender={onRender}>
    <Table />
  </Profiler>
}

function Table() {

  let [result, setResult] = useState<QueryResult>();
  let [id, update]= useInstantUpdater()

  useEffect(() => {
    async function load() {
        let persistence = buildLineItemsStorePersistence();

        let aggregate = await persistence.query(
          'Ampyr_LineItems',
        pQuery().withLineItems([
          "Net Revenue",
          "Operational Expense",
          "EBITDA",
          "Accounting Depreciation",
          "Net Profit Before Tax",
          "Variation in Depreciation",
          "Cash",
          "Capital Expenditure",
          "Taxable Income",
          "Net Taxable Income",
          "Construction",
          "Operation",
          "Post Contract Term",
          "Utilised Tax Loss Account",
          "Equity Injection",
          "First Data Point Time",
          "Last Data Point Time",
          "Net Cash",
          "Opening Balance (BS)",
          "Closing Balance (BS)",
          "Project Active",
          "Closing Balance (Equity Capital)",
          "Closing Balance (PPE)",
          "Opening Balance (PPE)",
          "Deferred Tax Asset",
          "Closing Balance Depreciation",
          "Opening Balance Depreciation",
          "Generated Tax Losses",
          "Utilised Tax Losses",
          "Deferred Tax Liability",
          "Closing Balance (BS)",
          "Opening Balance (BS)",
          "Net Cash",
          "Equity Injection",
          "Closing Balance (Equity Capital)",
          "Closing Balance (PPE)",
          "Opening Balance (PPE)",
          "Deferred Tax Asset",
          "Opening Balance Depreciation",
          "Closing Balance Depreciation",
          "Generated Tax Losses",
          "Utilised Tax Losses",
          "Deferred Tax Liability",
          "Net Cash Summed"
        ]).withCalculatedLineItems([
          // "Closing Balance (BS)",
          // "Opening Balance (BS)",
          // "Net Cash",
          // "Equity Injection",
          // "Closing Balance (Equity Capital)",
          // "Closing Balance (PPE)",
          // "Opening Balance (PPE)",
          // "Deferred Tax Asset",
          // "Opening Balance Depreciation",
          // "Closing Balance Depreciation",
          // "Generated Tax Losses",
          // "Utilised Tax Losses",
          // "Deferred Tax Liability",
          // "Net Cash Summed"
        ])
      );

      let resultsProcessingTime = new Date().getTime()


      aggregate.getDataSet().addTimedGroupingLineItemsByField('cname')

      let result = aggregate.query(StoreQuery.all()
        // .withTimeIndex(
        //   aggregate.timeIndex.sliced(0, 20)
        // )
        , {
        table: {
          aggregateColumnConfig: {},
        }
      })

      setResult(result)



      //  aggregate.queryBatched(StoreQuery.all(), undefined, (resultUpdate) => {
      //
      //    setResult((resultState) => {
      //       if(!resultState) {
      //         console.info("Setting columns");
      //         resultUpdate.collapsibleBy('fields.cname');
      //         return resultUpdate
      //       } else {
      //         resultState.addColumns(resultUpdate)
      //         update()
      //         return resultState
      //       }
      //
      //    })
      // });



       resultsProcessingTime  = new Date().getTime() - resultsProcessingTime
      console.log("Results processing time", resultsProcessingTime)

    }

    load();

  }, []);



  if(!result) {
    return <div>Loading...</div>
  }



  return <div>

    <LineItemsTable queryResult={result} withGroups={true} />
  </div>
}