import { Container, Grid, Segment } from "semantic-ui-react";
import { LoadScenarioFilterSection } from "./LoadScenarioFilterSection.component";
import { useEffect, useState } from "react";
import {
  AmProject,
  AmScenario,
  FiltersDetails,
  getStoreId,
} from "../../ps-types";
import { getScenarios } from "./scenarios.client";
import { useCompanyId } from "../../core";
import { useInstantUpdater } from "../../generic.hooks";
import { useUpdateOnGlobalContextChange } from "../builder/widgets/commons";
import { useDashboardService } from "../builder/DashboardConfigService";
import { ParameterLineItem } from "../../ps-models/line-items";
import { StoreQuery } from "../../ps-models/lineitems-store";
import { AmScenarioFilterSection } from "./AmScenarioFilterSection.component";
import { ScenarioActions } from "./ScenarioActions.component";
import { getAmProjects } from "../projects/amProject.client";
import { buildProjectVersionOptions } from "../projects/ProjectVersionSelection";

export type ScenarioContext = {
  storeIds: string[];
  paramsMap: AmScenario["paramsMap"];
  filterDetails: FiltersDetails | undefined;
};

export function ScenarioFilter() {
  let { getService } = useDashboardService();
  let dashboardService = getService();
  const filtersMap =
    dashboardService.getGlobalContext()?.filterDetails?.filtersMap;
  const initialFiltersMap =
    dashboardService.getGlobalContext()?.filterDetails?.initialFiltersMap;

  useUpdateOnGlobalContextChange({
    appContext: dashboardService,
    id: "ctxLoader.Filters",
  });

  const paramsMap: AmScenario["paramsMap"] = dashboardService
    .getConfig()
    .getExtraLineItems({ getByTypes: ["Parameter"] })
    .map((li) => {
      return { [li.name]: (li as ParameterLineItem).getValue().value };
    })
    .reduce((a, b) => ({ ...a, ...b }), {});

  let [selectedScenario, setSelectedScenario] = useState<string>("");
  let [scenarios, setScenarios] = useState<AmScenario[]>([]);
  let [compareWith, setCompareWith] = useState<string>("");
  let [scenarioTitle, setScenarioTitle] = useState<string>("");
  let [projects, setProjects] = useState<AmProject[]>();
  let [selectedContext, setSelectedContext] = useState<ScenarioContext>({
    storeIds: [],
    paramsMap: undefined,
    filterDetails: undefined,
  });
  let [compareWithContext, setCompareWithContext] = useState<ScenarioContext>({
    storeIds: [],
    paramsMap: undefined,
    filterDetails: undefined,
  });
  let [selectedContextInternal, setSelectedContextInternal] =
    useState<ScenarioContext>({
      storeIds: [],
      paramsMap: undefined,
      filterDetails: undefined,
    });
  const [actionButtonDisable, setActionButtonDisable] = useState<boolean>(true);
  const [projectVersionInitialSelections, setProjectVersionInitialSelections] =
    useState<Array<any>>([]);
  let compareWithName = "Comparing with: ";
  let companyId = useCompanyId();
  let [updateId, update] = useInstantUpdater();

  if (compareWith) {
    let compareWithScenario = scenarios.find((s) => s.id === compareWith);
    compareWithName = `Comparing with: <br/><br/><span style="color:black">${compareWithScenario?.name}</span>`;
  }
  let projectOptions =
    (projects &&
      buildProjectVersionOptions(projects)) ||
    [];
  useEffect(() => {
    const {
      storeIds: selectedStoreIds,
      ...persistedUserChoicesFromSelectedContext
    } = selectedContext;
    const {
      storeIds: compareWithStoreIds,
      ...persistedUserChoicesFromCompareWithContext
    } = compareWithContext;

    if (selectedStoreIds.length === 0) return;

    onSenarioChange(
      selectedStoreIds,
      compareWithStoreIds,
      {
        selected: persistedUserChoicesFromSelectedContext,
        compareWith: persistedUserChoicesFromCompareWithContext,
      },
      dashboardService
    );
  }, [selectedContext, compareWithContext]);

  useEffect(() => {
    getScenarios(companyId).then((scenarios) => {
      setScenarios(scenarios);
    });
  }, [companyId, updateId]);

  useEffect(() => {
    if (selectedContextInternal.storeIds.length > 0) {
      setSelectedContext(selectedContextInternal);
    }
  }, [selectedContextInternal]);

  useEffect(() => {
    if (selectedScenario) {
      let scenario: any = scenarios.find((s) => s.id === selectedScenario);
      if (scenario) {
        setSelectedContextInternal({
          storeIds: scenario.selectedProjectVersions.map((s: any) =>
            getStoreId(s.projectId, s.versionId)
          ),
          paramsMap: scenario?.paramsMap,
          filterDetails: {
            filtersMap: scenario?.filtersMap,
            initialFiltersMap: scenario?.filtersMap,
            filterQuery: buildQueryFromFilters(scenario?.filtersMap),
          },
        });
        setScenarioTitle(scenario.name);
      }
    }
  }, [selectedScenario]);

  useEffect(() => {
    if (compareWith) {
      let scenario = scenarios.find((s) => s.id === compareWith);
      if (scenario) {
        let compareStoreIds = scenario.selectedProjectVersions.map((s) =>
          getStoreId(s.projectId, s.versionId)
        );
        let compareStoreParamsMap = scenario?.paramsMap;
        compareStoreIds = Array.from(new Set(compareStoreIds));
        setCompareWithContext({
          storeIds: compareStoreIds,
          paramsMap: compareStoreParamsMap,
          filterDetails: undefined,
        });
      }
    }
  }, [compareWith]);

  useEffect(() => {

    if(!dashboardService.getConfig().isProjectVersionSelectorExposed()) {
      return;
    }

    getAmProjects(companyId).then((projects) => {
      setProjects(projects);
      if (!selectedScenario) {
        setSelectedContextInternal({
          storeIds:
            projects
              ?.filter((p) => p.defaultStoreId)
              .map((p) => p.defaultStoreId!) ?? [],
          paramsMap: undefined,
          filterDetails: undefined,
        });
        setScenarioTitle("Latest Versions");
        // setLoading(false);
        setProjectVersionInitialSelections(
          projects
            ?.filter((p) => p.defaultStoreId)
            .map((p) => p.defaultStoreId!) ?? []
        );
      }
    });
  }, [companyId]);

  return (
    <Container style={{ paddingBottom: "20px" }}>
      <Segment>
        <Grid>
          <Grid.Row>
            <LoadScenarioFilterSection
              scenarioTitle={scenarioTitle}
              setSelectedScenario={setSelectedScenario}
              setCompareWithContext={setCompareWithContext}
              compareWith={compareWith}
              setCompareWith={setCompareWith}
              compareWithName={compareWithName}
              selectedScenario={selectedScenario}
              update={update}
            />
            <ScenarioActions
              initialFiltersMap={initialFiltersMap}
              scenarioTitle={scenarioTitle}
              projectOptions={projectOptions}
              scenarios={scenarios}
              selectedContextInternal={selectedContextInternal}
              currentParamsMap={paramsMap}
              currentFiltersMap={filtersMap}
              selectedScenario={selectedScenario}
              setSelectedScenario={setSelectedScenario}
              setScenarioTitle={setScenarioTitle}
              update={update}
              setSelectedContextInternal={setSelectedContextInternal}
              projectVersionInitialSelections={projectVersionInitialSelections}
              actionButtonDisable={actionButtonDisable}
              setActionButtonDisable={setActionButtonDisable}
            />
          </Grid.Row>
          <AmScenarioFilterSection
            setSelectedContextInternal={setSelectedContextInternal}
            setScenarioTitle={setScenarioTitle}
            filtersMap={filtersMap}
            paramsMap={paramsMap}
            setClearButtonDisabled={setActionButtonDisable}
            projects={projects!}
          />
        </Grid>
      </Segment>
    </Container>
  );
}

const onSenarioChange = (
  selectedStores: any,
  compareWithStores: any,
  persistedUserChoices: any,
  dashboardService: any
) => {
  dashboardService
    .getConfig()
    .setQuery(
      dashboardService.getConfig().getQuery().withStoreIds(selectedStores)
    );

  dashboardService.setStoreIdsForScenarioComparison(compareWithStores);

  if (persistedUserChoices) {
    let updatesToGlobalContext: Record<string, any> = {};
    for (let key in persistedUserChoices.selected) {
      updatesToGlobalContext[key] = persistedUserChoices.selected[key];
    }
    for (let key in persistedUserChoices.compareWith) {
      if (persistedUserChoices.compareWith[key]) {
        updatesToGlobalContext[key] = persistedUserChoices.compareWith[key];
      }
    }
    // TODO Kap: It is not being used anywhere, need to discuss.
    // if (persistedUserChoices?.compareWith?.compareWithParamsMap !== undefined) {
    //   updatesToGlobalContext["compareWithParamsMap"] =
    //     persistedUserChoices?.compareWith?.compareWithParamsMap;
    // }
    // TODO Kap: It is not being used anywhere, need to discuss.
    // if (persistedUserChoices?.compareWith?.filtersMap !== undefined) {
    //   updatesToGlobalContext["compareWithFiltersMap"] =
    //     persistedUserChoices?.compareWith?.filtersMap;
    // }
    if (Object.keys(updatesToGlobalContext).length !== 0) {
      dashboardService.updateGlobalContext(updatesToGlobalContext);
    }
  }
};
export function buildQueryFromFilters(
  filters: Record<string, string[]> = {}
): StoreQuery {
  let query: StoreQuery = StoreQuery.all();

  for (let [k, values] of Object.entries(filters)) {
    if (values && values[0]) {
      let orQuery = StoreQuery.byField(k, values[0]);
      for (let i = 1; i < values.length; i++) {
        orQuery = orQuery.or(StoreQuery.byField(k, values[i]));
      }
      query = query.and(orQuery);
    }
  }

  //We only care about filtering the aggregate children, everything else stays.
  query = query.or(StoreQuery.withField("store_sourceId").not());

  return query;
}
