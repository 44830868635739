import {Checkbox, Input, Segment, SegmentInline} from "semantic-ui-react";
import {GranularitySelector} from "../../../lineitems-store/DateRange";
import React, {useState} from "react";
import {TimeUnits} from "../../../ps-models";
import {LineItemsStore} from "../../../ps-models/lineitems-store";
// @TODO: The types for startDate and endDate needs to have only oneOf the keys.
export interface TimeLineConfigurations {
    startDate?: Partial<{ useReferenceDateParameterLi: string , useCurrentDate: boolean, fixedDate: Date }>;
    endDate?: Partial<{ useReferenceDateParameterLi: string , useCurrentDate: boolean, fixedDate: Date }>;
    granularity?: TimeUnits;
}

export const TimeLineConfigurator = ({store, timeLineConfig, onTimeLineConfigChange}:{store: LineItemsStore, timeLineConfig?: TimeLineConfigurations, onTimeLineConfigChange: (timeLineConfig: TimeLineConfigurations | undefined)=>void})=> {
    let [customizeTimeLineConfig, setCustomizeTimeLineConfig] = useState<boolean>(!!timeLineConfig);

    return <Segment>
        <Checkbox toggle checked={customizeTimeLineConfig}
                  onChange={(e, data) => {
                      if(!!data.checked === false){
                          onTimeLineConfigChange(undefined);
                      }
                      setCustomizeTimeLineConfig(!!data.checked)
                  }}
                  label={"Customize TimeLine Configurations"} />
        {customizeTimeLineConfig && <>
            <br/>
            <strong>TimeLine Configurations</strong>
            <div>
                Granularity:
                <GranularitySelector initialSelection={timeLineConfig?.granularity} store={store} allowNoneSelection={true} onSelectionChange={(selectedGranularity)=>{
                    onTimeLineConfigChange({...(timeLineConfig ?? {}), granularity: selectedGranularity});
                }} />
            </div>
            <SegmentInline>
                <strong>Start Date:</strong>
                <div>
                    Reference Date Parameter Line Item: <Input value={timeLineConfig?.startDate?.useReferenceDateParameterLi} onChange={(e, data) => {
                    onTimeLineConfigChange({...(timeLineConfig ?? {}), startDate: { useReferenceDateParameterLi: data.value }});
                }} />
                </div>
            </SegmentInline>
        </>}
    </Segment>
}

export function getDatesFromTimeLineConfig(store: LineItemsStore, timeLineConfig: TimeLineConfigurations){
    let startDate: Date | null = null;
    let endDate: Date | null = null;
    for(let dateName of ["startDate", "endDate"]){
        let dateKindConfig = dateName === "startDate" ? timeLineConfig?.startDate : timeLineConfig?.endDate;
        let extractedDate: Date | null = null;
        if(dateKindConfig?.useReferenceDateParameterLi){
            let paramValue = store.getParam(dateKindConfig?.useReferenceDateParameterLi) as number | undefined;
            if(paramValue){
                extractedDate = new Date(paramValue ?? 0);
            }
        } else if(dateKindConfig?.fixedDate){
            extractedDate = dateKindConfig?.fixedDate;
        } else if(dateKindConfig?.useCurrentDate){
            extractedDate = new Date();
        }

        if(dateName === "startDate"){
            startDate = extractedDate;
        } else if(dateName === "endDate"){
            endDate = extractedDate;
        }
    }

    return {startDate, endDate}
;}