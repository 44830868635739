import {Button, Modal} from "semantic-ui-react";
import React, {ReactElement} from "react";
import {StrictModalProps} from "semantic-ui-react/dist/commonjs/modules/Modal/Modal";

interface ContentPopupProps extends StrictModalProps  {
    onClose: () => void, header: string | ReactElement,  children: ReactElement | ReactElement[],
}
export function ContentPopup(props: ContentPopupProps): ReactElement {
    const localProps = {...props};
    if(!localProps.size){
        localProps.size = 'tiny';
    }
    if(localProps.closeOnEscape === undefined){
        localProps.closeOnEscape = true;
    }
    const {children, header , ...modalProps} = localProps;

    return <Modal
        open={true}
        {...modalProps}
    >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
            {children}
        </Modal.Content>
        <Modal.Actions>
            <Button color="blue" onClick={() => localProps.onClose()}>
                Close
            </Button>
        </Modal.Actions>
    </Modal>
}
