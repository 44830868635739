import { fromPairs, values} from "ramda";
import {buildDailyTimeIndex, buildTimeIndex, TimeIndex, TimeUnitView} from "../Time.model";
import { TimeIndexDto} from "../Time.model";
import {
  buildParameterLineItem,
  PARAMETER_LINE_ITEM_TYPE,
  ParameterLineItem,
  REPEATING_LINE_ITEM,
  RepeatingLineItem
} from "../line-items";
import { StoreQuery} from "./StoreQuery";
import {
  buildTimedCalculatedLineItem,
  buildTimedRawLineItem,
  field as makeField, isTemplateLineItem,
  isTimedLineItem,
  LineItem,
  TEMPLATE_LINE_ITEM_TYPE,
  TemplateLineItem,
  TIMED_CALCULATED_LINE_ITEM_TYPE,
  TIMED_RAW_LINE_ITEM_TYPE,
  TimedCalculatedLineItem,
  TimeDefinition,
  TimedRawLineItem,
} from "../line-items";
import {entries, normalizeMapKeys, normalizeString} from "../line-item-utils/coding.utils";
import {QueryResult, ResultCell, TimeColumn} from "./QueryResult";
import {LineItemDataSet} from "../LineItemDataSet";
import {InvalidArgument} from "../exceptions";
import {LineItemsStoreExecution} from "./LineItemsStoreExecution.model";
import {LineItemStoreLogs} from "../LineItemStoreLogs";
import { PersistenceQuery, PersistenceQueryDto } from "./PersistenceQuery";
import {INTERNAL_FIELD} from "./constants";
import {ResultOptions, QueryRunner} from "./query-runner";


export type StoreType = "document" | "template"

export const STORE_TYPE_DOCUMENT: StoreType = "document"
export const STORE_TYPE_TEMPLATE: StoreType = "template"

export interface LineItemsStoreOptions {
  id?: string,
  name?: string //Human-readable name for the store
  storeType?: StoreType
  imports?: string[] //Only non-template stores can have template imports, only template stores can be imported
  logs?: LineItemStoreLogs,
}

export interface LineItemsStoreDto {
  id?: string;
  name?: string;
  imports: string[];
  storeType : StoreType;
  timeIndex: TimeIndexDto;
  lineItems: any;
  sourceQuery?: PersistenceQueryDto;
  lastFastUpdate?: number;
}

export class LineItemsStore {
  private id?: string;
  private name?: string;
  lastExecution?: LineItemsStoreExecution;
  private imports: string[] = [];
  private type: boolean = false;

  private dataSet: LineItemDataSet;
  private storeType : StoreType;

  private logs: LineItemStoreLogs = new LineItemStoreLogs();

  private sourceQuery?: PersistenceQuery;

  constructor(public timeIndex: TimeIndex, options?:LineItemsStoreOptions) {

    if(options) {
      if(options?.storeType === "template" && options.imports && options.imports.length > 0) {
         throw new InvalidArgument("Only non-template stores can have imports");
      }
    }

    this.id = options?.id;
    this.name = options?.name || this.id;
    this.dataSet = new LineItemDataSet();
    this.imports = options?.imports || [];
    this.storeType = options?.storeType || "document";
  }

  setSourceQuery(query: PersistenceQuery) {
    this.sourceQuery = query;
  }

  getSourceQuery() {
    return this.sourceQuery;
  }

  get isTemplate() {
    return this.storeType === "template";
  }

  getStoreType() {
    return this.storeType;
  }

  setStoreType(storeType: StoreType) {
    if(storeType === "template" && this.imports.length > 0) {
      throw Error("Only non-template stores can have imports");
    }
    this.storeType = storeType;
  }

  getImports() {
    return this.imports;
  }

  addImport(storeId: string) {
    let importsSet = new Set(this.imports);
    importsSet.add(storeId);

    this.imports = Array.from(importsSet);
  }

  clearExecutionCache() {
    this.lastExecution = undefined;
  }

  getDataSet() {
    return this.dataSet;
  }

  getId() {
    return this.id
  }

  setId(id: string) {
    this.id = id;
  }

  getName() {
    return this.name
  }

  setName(name: string) {
    this.name = name;
  }

  getExecution() {
    if(!this.lastExecution) {
      this.lastExecution = this.buildExecution();
    }
    return this.lastExecution;
  }

  getAggregatedLogs(){
    return LineItemStoreLogs.merge(this.logs, this.getExecution().getLogs(), this.getDataSet().getLogs());
  }

  buildExecution() {
    let expandedDataSet = this.dataSet.expand();
    let ex = new LineItemsStoreExecution(expandedDataSet,  this.timeIndex, this.name || "");
    ex.processDynamicFields();
    return ex;
  }


  /**
   *
   * @param query
   * @param options.recalculate By default the query only filter's line items but doesn't change any existing value.
   *    with recalculate=true, the CalculatedLineItems will consider only the lineItems that have been included in the query.
   */
  query(query: StoreQuery, options: ResultOptions = {}): QueryResult {
    let queryRunner = new QueryRunner(this)
    this.buildExecution().processDynamicFields()
    return queryRunner.execute(query, {
      sourceQuery: this.sourceQuery,
      ...options
    });
  }

  async streamedQuery(query: StoreQuery, options: ResultOptions = {}, onData: (result: QueryResult) => void): Promise<void> {

  }


  //@Experimental
  queryBatched(query: StoreQuery, options: ResultOptions = {}, onData: (result: QueryResult) => void): void {

    //Create multimple timeIndexes of the same size
    let timeLine = this.timeIndex.timeLine;

    //create Chunks
    let chunkSize = 10;
    let chunks: TimeUnitView[][] = [];

    for(let i = 0; i < timeLine.length; i += chunkSize) {
      chunks.push(timeLine.slice(i, i + chunkSize));
    }

    let timeIndexes = chunks.map((chunk) =>
      buildTimeIndex(chunk[0].date, chunk[chunk.length - 1].date, this.timeIndex.getUnit())
    );

    let queryRunner = new QueryRunner(this);

    //Run in batches and sleep
    let interval = setInterval(() => {
      console.info("Processing time", timeIndexes.length)
      if(timeIndexes.length === 0) {
        clearInterval(interval);
        return;
      }
      let t = timeIndexes.shift()!;
      let result = queryRunner.execute(query.withTimeIndex(t), {
        sourceQuery: this.sourceQuery,
        ...options,
      });
      onData(result);
    }, 300);

  }

  getLineItems(query: StoreQuery) {
    let queryRunner = new QueryRunner(this)
    return queryRunner.getLineItems(query);
  }

  materializeTimed(query: StoreQuery, options: ResultOptions = {}, frozeCalculated = false, preserveLiTimeDefUnit =false): LineItemsStore {
    let queryRunner = new QueryRunner(this)
    let res = queryRunner.execute(query, options);


    if(res.columns.length < 2) {
      return this;
    }

    let timeline = res.columns
      .filter((col) => col.type == "time")
      .map((col) =>  new Date((col as TimeColumn).time) );

    this.getExecution().processDynamicFields()

    let newStore = new LineItemsStore(
      buildTimeIndex(
        timeline[0],
        timeline[timeline.length - 1],
        (query.timeIndex || this.timeIndex).getUnit(),
      )
    );


    res.rows.forEach((row) => {
      let liName = row.name.value.toString();
      let liValues = Object.values(row).map(cell => cell as ResultCell)
        .filter(cell => cell.type === 'lineItemValue' ).map(cell => cell.value)

      let currentLi = this.getDataSet().getLineItem(liName);


      if(!frozeCalculated && currentLi instanceof TimedCalculatedLineItem) {
        const currentLiTimeDef = currentLi.getTimeDefinition();
        const liWithStoresGranularity = buildTimedCalculatedLineItem(liName,
            new TimeDefinition(currentLiTimeDef.aggregator, currentLiTimeDef.spread, preserveLiTimeDefUnit ? currentLiTimeDef.granularity : newStore.timeIndex.getUnit()),
            currentLi.fn,
            currentLi.fields
        )
        newStore.getDataSet().addLineItem(liWithStoresGranularity);
      } else if(currentLi instanceof ParameterLineItem) {
        newStore.getDataSet().addLineItem(currentLi.clone());
      } else if(isTimedLineItem(currentLi)) {
        const currentLiTimeDef = currentLi.getTimeDefinition();
        let li = buildTimedRawLineItem(liName, new TimeDefinition(currentLiTimeDef.aggregator, currentLiTimeDef.spread, preserveLiTimeDefUnit ? currentLiTimeDef.granularity : newStore.timeIndex.getUnit()), currentLi.fields.clone());
        liValues.forEach((value, index) => {
          if(value) {
            li.add(timeline[index], value);
          }

        });
        newStore.getDataSet().addLineItem(li);
      } else {
        let errMsg = "Not implemented " + currentLi.constructor.name + " " + liName;
        console.error(errMsg);
        this.logs.error(errMsg);
        //throw Error("Not implemented " + currentLi.constructor.name + " " + liName);
      }

    });

    return newStore;
  }

  view(query: StoreQuery, persistStoreMetaData= false): LineItemsStore {
    let newStore = persistStoreMetaData ? new LineItemsStore(this.timeIndex,
        {
        ...(this.id ? {id: this.id} : {}),
        ...(this.name ? {name: this.name} : {}),
        ...(this.storeType ? {storeType: this.storeType} : {}),
        ...(this.imports ? {imports: this.imports} : {}),
           }): new LineItemsStore(this.timeIndex);
    this.buildExecution();
    newStore.dataSet = this.dataSet.createView(query);
    return newStore;
  }



  preComputeValues() {
    //
    let execution = this.getExecution()

    //Get all the calculated line items
    this.getDataSet().getLineItemsByType(TIMED_CALCULATED_LINE_ITEM_TYPE).forEach((li) => {
      let calculatedLi = li as TimedCalculatedLineItem;
      calculatedLi.preComputeValues(execution);
    });

  }


  serialize(): LineItemsStoreDto {

    return {
      id: this.id,
      name: this.name,
      timeIndex: this.timeIndex.serialize(),
      imports: this.imports,
      storeType: this.storeType,
      lineItems: fromPairs(entries(this.dataSet.getLineItems())
        .map(([k, li]) => [k, li.serialize()])
      ),
      sourceQuery: this.sourceQuery?.serialize(),
    }
  }

  static deserialize(serializedStore: any): LineItemsStore {

    let store = new LineItemsStore(
      TimeIndex.deserialize(serializedStore.timeIndex), serializedStore.metadata);

    store.name = serializedStore.name;
    store.id = serializedStore.id;
    store.addSerializedLineItems(serializedStore.lineItems);
    store.imports = serializedStore.imports || [];
    store.storeType = serializedStore.storeType || "document";
    if(serializedStore.logs){
      store.logs = LineItemStoreLogs.deserialize(serializedStore.logs);
    }
    store.sourceQuery = serializedStore.sourceQuery ? PersistenceQuery.deserialize(serializedStore.sourceQuery) : undefined;
    return store;
  }

  addSerializedLineItems(lineItems: any, config: {
    materializeByDefault?: boolean,
    materializationMap?: Record<string, boolean>//LineItemName -> (true: materialize, false: don't materialize)
  } = {}) {

    let materializationMap = normalizeMapKeys( config.materializationMap || {});

    let nonMaterializedCount = 0;


    for (let li of values(lineItems)) {
      let shouldMaterialize = !!config.materializeByDefault;

       {
        let cName = normalizeString(li.fields?.cname?.value || li.name);
        if(cName) {

          shouldMaterialize = materializationMap[cName] !== undefined ? materializationMap[cName] : shouldMaterialize;
          if(!shouldMaterialize) {
            nonMaterializedCount++;
          }
        }
      }

      let dli = LineItemsStore.deserializeLineItem(li, shouldMaterialize);
      if (dli) {
        this.dataSet.addLineItem(dli);
      } else {
        let msg = `Could not deserialize line item ${JSON.stringify(li)}`
        console.warn(msg)
        this.logs.warn(msg)
      }
    }

    console.log(`${nonMaterializedCount} non-materialized Calculated line items, ${this.name}`)
  }

  static deserializeLineItem(li: any, materialized: boolean = false): LineItem | undefined {
      switch (li.type) {
        case PARAMETER_LINE_ITEM_TYPE:
        case "SingleLineItem":
          return ParameterLineItem.deserialize(li);
        case TIMED_CALCULATED_LINE_ITEM_TYPE:
          if(materialized) {
            return TimedRawLineItem.deserialize(li);
          } else {
            return TimedCalculatedLineItem.deserialize(li);
          }
        case TIMED_RAW_LINE_ITEM_TYPE:
          return TimedRawLineItem.deserialize(li);
        case TEMPLATE_LINE_ITEM_TYPE:
          return TemplateLineItem.deserialize(li);

        case REPEATING_LINE_ITEM:
          return RepeatingLineItem.deserialize(li)
      }
  }

  getParam(lineItem: string) {
     return this.getDataSet().getParam(lineItem);
  }


  setImports(imports: string[]) {
    this.imports = imports;
  }

  clone() {
    let clone = new LineItemsStore(this.timeIndex,
        {
          ...(this.id ? {id: this.id} : {}),
          ...(this.name ? {name: this.name} : {}),
          ...(this.storeType ? {storeType: this.storeType} : {}),
          ...(this.imports ? {imports: this.imports} : {}),
        })
    clone.getDataSet().addLineItems(Object.values(this.getDataSet().getLineItems()));
    return clone;
  }

  buildSitesMetadataStore(): LineItemsStore {
    let metadataStore = new LineItemsStore(buildDailyTimeIndex(new Date('2023-01-02'), new Date('2023-01-01')));
    const allSiteNamesInAggregatedStore = this.getDataSet().getAggregatedStoreNames();
    for(let siteName of allSiteNamesInAggregatedStore){
      const siteLineItems = this.getDataSet().getByField('store_sourceName', siteName as string)
      const firstSiteLineItem = siteLineItems[0]
      let lineItem = buildParameterLineItem(siteName as string, siteName,
          firstSiteLineItem.fields
      );
      metadataStore.getDataSet().addLineItem(lineItem);
    }

    return metadataStore;
  }
}

export const mergeTemplateStoreIntoStore = (destinationStore: LineItemsStore, template: LineItemsStore, overwrite: boolean = true) => {
  if(template.getStoreType() !== "template"){
    throw new Error("Invalid template store supplied");
  }
  let importableLineItems = Object.values(template.getDataSet().getLineItems())
      .filter(lineItem => !lineItem.fields.hasField(INTERNAL_FIELD));

  for(let lineItemFromTemplateStore of importableLineItems) {
    const isATemplateLineItem = isTemplateLineItem(lineItemFromTemplateStore)
    let lineItemInDestination = destinationStore.getDataSet().getLineItem(lineItemFromTemplateStore.name);

    if(isATemplateLineItem && lineItemInDestination){
      const destinationLiToPersist = lineItemInDestination.clone();
      // @TODO: Remove this, once all template line items are migrated and have a timeDefinition
      // const timeDefOnTemplate = (lineItemFromTemplateStore as TemplateLineItem)?.timeDefinition ?? buildDailyTimeDef();
      for(let field of lineItemFromTemplateStore.fields.getFields()){
        let fieldExistsOnDestinationLi = destinationLiToPersist.fields.hasField(field.name);
        if(!overwrite && fieldExistsOnDestinationLi){
          continue;
        }
        destinationLiToPersist.fields.addField(field.name, field.value, field.label);
        destinationStore.getDataSet().addLineItem(destinationLiToPersist);

        if(field.name === 'store_label' || field.name === 'store_valueType' || field.name === 'section'){ // @TODO: HACK to update label in child site stores in an aggregated store.
          destinationStore.getDataSet().getByField('store_sourceLineItemName', lineItemFromTemplateStore.name).forEach(li => {
            let site = destinationStore.getDataSet().getLineItem(li.name).fields.getFieldStr('store_sourceName');
            if(site){
              if(field.name === 'store_label'){
                destinationStore.getDataSet().addFieldToLineItem(li.name, makeField('store_label', `${site} - ${field.value}`))
                destinationStore.getDataSet().addFieldToLineItem(
                    li.name,
                    makeField('store_sourceLabel', field.value as string)
                )
              } else {
                destinationStore.getDataSet().addFieldToLineItem(li.name, makeField(field.name, field.value as string))
              }
            }
          });
        }
      }
      // @TODO: Start supporting this in a future release, for now we are only merging templateLineItems, without their timeDef
      // // @TODO: Remove the check for timeDefOnTemplate, once all template line items are migrated and have a timeDefinition
      // if(isTimedLineItem(destinationLiToPersist) && timeDefOnTemplate){
      //   destinationStore.getDataSet().addLineItem(destinationLiToPersist.withDefinition(timeDefOnTemplate))
      // }
    }
    // @TODO: Start supporting this in a future release, for now we are only merging templateLineItems
    // else if(!isATemplateLineItem && (overwrite || !lineItemInDestination)) {
    //   //Todo: This is dangerous as it's mutating the template line item, we should implement lineItem clone
    //   lineItemFromTemplateStore.fields.addField('store_importedFrom', template.getId()!!);
    //   destinationStore.getDataSet().addLineItem(lineItemFromTemplateStore);
    // }
  }
}