import {useCompanyId} from "../../core";
import {
  AmProject, AmProjectVersionCreationResponse, getStoreId,
  ProjectVersion,
} from "../../ps-types";
import React, {useEffect, useState} from "react";
import {Button, Grid, Segment} from "semantic-ui-react";
import {ProjectVersionSelection} from "../projects/ProjectVersionSelection";
import {LoadingBlock} from "../../ui/Loading";
import {useMessages} from "../../ui/MessagesProvider";
import {getAmProjects, reprocessAmProjectVersion} from "../projects/amProject.client";

import { from } from 'rxjs';
import { mergeMap, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {ProjectVersionMappingLogs} from "./ProjectTemplate.page";
import {PsFileUploader} from "../../ui/FileUploader.component";

export function ProjectModelReprocessor() {
  const [projects, setProjects] = useState<AmProject[]>();
  const [selectedProjectVersions, setSelectedProjectVersions] = useState<ProjectVersion[]>([]);
  let [uploadProgress, setUploadProgress] = useState<number>(0);
  let [file, setFile] = useState<File | undefined>();


  const [progress, setProgress] = useState(0);
  const [mappingErrors, setMappingErrors] = useState<AmProjectVersionCreationResponse['errors']>()
  const [mappingWarnings, setMappingWarnings] = useState<AmProjectVersionCreationResponse['warnings']>()
  const [running, setRunning] = useState(false);
  const {clear, error} = useMessages();

  const companyId = useCompanyId();

  useEffect(() => {
    async function load() {
      let projects = await getAmProjects(companyId);
      console.info("Projects loaded", projects.length)
      setProjects(projects);
    }

    load().catch(e => error(e.message));

  }, [companyId]);


  if (!projects) return <LoadingBlock/>;


  const fileUploadCallback = (file: File) => {
    clear();
    if(!file) return;
  };

  const triggerReprocess = async () => {
    const apiCalls = selectedProjectVersions?.map((projectVersion)=>{
      const {projectId, versionId} = projectVersion;
      return {
        id: getStoreId(projectVersion.projectId, projectVersion.versionId),
        call: ()=> reprocessAmProjectVersion(
            companyId,
            projectId,
            versionId,
            file ?? null,
            (progress) => setUploadProgress(progress)
        )
      }
    })
    if(apiCalls){
      const executeApiCalls = () => {
        setRunning(true);
        setProgress(0);
        setMappingErrors([]);
        setMappingWarnings([]);


        from(apiCalls)
            .pipe(
                mergeMap((apiCall, index) =>
                        from(apiCall.call()).pipe(
                            tap(() => setProgress(((index + 1) / apiCalls.length) * 100)),
                            catchError(error => {
                              const eMsg = `${apiCall.id}:${error instanceof Error ? error.message : 'Unknown error'}`
                              setMappingErrors(prevData => [...(prevData ?? []), eMsg])
                              return of(null); // Continue with the next call
                            }),
                            tap(result => {
                              if (result !== null) {
                                const errors = result.errors.map((msg)=>`${apiCall.id}:${msg}`);
                                const warnings = result.warnings.map((msg)=>`${apiCall.id}:${msg}`);
                                setMappingErrors(prevData => [...(prevData ?? []), ...errors]);
                                setMappingWarnings(prevData => [...(prevData ?? []), ...warnings]);
                              }
                            })
                        ),
                    1 // Concurrency limit
                )
            )
            .subscribe({
              // next: (result) => {
              //   if (result) {
              //     if(result.errors.length){
              //       setMappingErrors(()=>result.errors.map((msg)=>`${result.versionData?.versionId}`))
              //       setErrors(())
              //     }
              //     setData(prevData => [...(prevData ?? []), {...result}]);
              //     console.info("What is the result??", result);
              //   }
              // },
              error: (error) => {
                console.error('Unexpected error', error);
              },
              complete: () => {
                setRunning(false);
              }
            });
      };
      executeApiCalls();
    }
  }

  const handleProjectVersionsChange = (newlySelectedProjectVersions: ProjectVersion[]) => {
    console.info("Selected project versions", selectedProjectVersions);
    setSelectedProjectVersions(newlySelectedProjectVersions);
  }

  //What If Scenario Detail
  return <Segment style={{padding: "50px"}}>
    <div style={{position:'absolute', top: 10, left: 10}}>
      <Button basic labelPosition='left' icon='left chevron' size={"mini"} onClick={() => window.history.back()}
              content='Back'
      />
    </div>

    <h1>Project Version Reprocessor</h1>
    <Grid>
      <div>
        <div>Progress: {progress}%</div>
        <progress value={progress} max="100" />

      </div>
      <Grid.Row columns={2}>
        <Grid.Column>
          {"Total Selected for this Run:"}
          <strong>{selectedProjectVersions.length}</strong>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row columns={2}>
        <Grid.Column>
          <strong>Project Version</strong>
        </Grid.Column>
        <Grid.Column>
          <ProjectVersionSelection
              projects={projects}
              selectedProjectVersions={selectedProjectVersions}
              handleChange={handleProjectVersionsChange}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Segment>
      <div>
        <PsFileUploader
            uploadProgress={uploadProgress}
            setFile={(file)=> setFile(file) }
        />
        <Button disabled={running} onClick={triggerReprocess} primary>Run</Button>
      </div>
    </Segment>
    <Segment>
      <h2>Logs</h2>
      <ProjectVersionMappingLogs messages={{errors: mappingErrors, warnings: mappingWarnings ?? []}} />
    </Segment>
  </Segment>
}