import {Props as ReactApexChartsProps} from "react-apexcharts";
import * as React from "react";
import {ApexOptions} from "apexcharts";
import {QueryResult, TimeColumn} from "../ps-models/lineitems-store";
import {Segment} from "semantic-ui-react";
import {
  compareNormalized,
  FinancialValueType,
  formatDateByTimeUnit,
  getLastDateOfPeriodInUTC,
  TimeUnits,
  valueAsNumber
} from "../ps-models";
import { formatValueWithValueType } from "../ps-models/formatting";

import {ValueType} from "../ps-models/line-items";
import {EChartConfigurableOptions, useECharts} from "../assets-management/builder/EchartsWrapper";

export type TimeSeriesChartOptionsV2 = {
  type?: "line" | "bar";
  granularity: TimeUnits
  lineItemSeriesDefinitionOverrides?: { name: string, config: Omit<EChartConfigurableOptions['series'], "data"> }[];
  dateFormat?: string;
  plotAtLastDatesOfPeriod?: boolean;
  // stackingConfig?: {
  //   relativeToLi?: string;
  // },
  // projectionsStartDate?: Date;
  customValueFormatter?: (value: ValueType, valueType: FinancialValueType, decimalPlacesInValue?: number) => string;
}

interface TimeSeriesChartPropsV2 {title: string, result: QueryResult, maxItems?:number, options: TimeSeriesChartOptionsV2}

export function TimeSeriesChartV2({title, result, options, maxItems = 8}:TimeSeriesChartPropsV2) {
  let series: EChartConfigurableOptions['series'] = [];
  let defaultChartType: ReactApexChartsProps['type'] = (options?.type ?? 'line');

  let timeLine: TimeColumn[] = result.columns.filter(col => col.type === 'time').map(col => col as TimeColumn);

  let annotations: ApexOptions["annotations"] | undefined;
  let chartYAxisCount = Math.max(...options!.lineItemSeriesDefinitionOverrides?.map((item)=>((item as any).config?.['yAxisIndex'] as number | undefined ?? 0)) ?? [0])+1;
  let yAxisValueTypes: ValueType[] = [];

  // @TODO: Add support
  // if(timeLine.length>1 && options.projectionsStartDate){
  //   const projectionsStartTime = options.projectionsStartDate.getTime();
  //   const lastDateInTimeLineInMs = timeLine[timeLine.length-1].time
  //   if(projectionsStartTime < lastDateInTimeLineInMs){
  //     // @TODO: This is a hack to avoid cases, where the cutoffTime is very near for e.g. at a day's gap, in such cases we still need to cover the remaining width of the graph.
  //     const anYearBuffer = (1000*60*60*24*400);
  //     annotations = {
  //       xaxis:[
  //         {
  //           x: projectionsStartTime,
  //           x2: lastDateInTimeLineInMs + anYearBuffer,
  //           borderColor: "#FEB019",
  //           strokeDashArray: 0,
  //           fillColor: '#bcd85f',
  //           label: {
  //             borderColor: "#FEB019",
  //             style: {
  //               color: "#fff",
  //               background: "#FEB019",
  //             },
  //             text: 'Projected Data',
  //             orientation: "horizontal",
  //           }
  //         }
  //       ]
  //     };
  //   }}

  let defaultValueType = "number";

  // @TODO: Add support
  //TODO: We need to exclude the relativeToLi from the chart for the y-axis to be correct, however this breakes the percent tooltip, we should try to calculate the percent from result.rows instead
  // const excludeRelativeLi = (r: any)=> !options?.stackingConfig?.relativeToLi
  //   || !compareNormalized(options.stackingConfig.relativeToLi, r.name.value as string)

  const valueTypeMap: Record<string, FinancialValueType> = {}

  let lineItemsToProcess = result.rows
    // .filter(excludeRelativeLi)
      .slice(0, maxItems);

  for(let row of lineItemsToProcess) {
    let valueType = "number";
    let data: number[][] = [];
    const name = row.name.text.toString();
    let yAxisIndexForSeries = 0;

    for(let col of timeLine){
      valueType = result.rowColumnValue(row, "store_valueType")?.toString() || "";

      valueTypeMap[name] = valueType ? (valueType as FinancialValueType) : "number";
      let value = valueAsNumber(row[col.columnId].value);
      if(isFinite(value)) {
        // @TODO: Tie this to granularity
        data.push([(options?.plotAtLastDatesOfPeriod ? getLastDateOfPeriodInUTC(new Date(col.time), "months").getTime() : col.time),value]);
      }
    }
    let seriesData: any = {name, data}
    if(Array.isArray(options!.lineItemSeriesDefinitionOverrides) && options!.lineItemSeriesDefinitionOverrides.length>0){
      const seriesIndex = options!.lineItemSeriesDefinitionOverrides.findIndex((item)=>(compareNormalized((item as {name: string}).name, name)))

      if(seriesIndex !== -1) {
        seriesData = {...seriesData, ...options?.lineItemSeriesDefinitionOverrides[seriesIndex].config}
        yAxisIndexForSeries = (options?.lineItemSeriesDefinitionOverrides[seriesIndex]?.config as any)?.['yAxisIndex'] ?? 0;
      }
    }
    if(!seriesData.type){
      seriesData.type = defaultChartType;
      if(seriesData.type === "line"){
        seriesData.smooth = true;
      }
    }
    if(!yAxisValueTypes[yAxisIndexForSeries]){
      yAxisValueTypes[yAxisIndexForSeries] = valueType;
    }
    series.push(seriesData);
  }

  const valueFormatter = options?.customValueFormatter || formatValueWithValueType;

  function getFormattedValue(value: number, seriesIndex: number): string {
    const itemName = (series ?? [] as any)?.[seriesIndex]?.name;
    const valueTypeForItem = itemName ? (valueTypeMap[itemName] ?? defaultValueType): defaultValueType;
    return valueFormatter(value,valueTypeForItem as FinancialValueType);
  }

  let defaultOptions: EChartConfigurableOptions = {
    title: {
      text: title,
      textAlign: 'left',
    },
    grid: {
      left: '3%',
      right: '3%',
      bottom: '28%', // Increased bottom margin to avoid overlap
      containLabel: true,
    },
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 10,
        minValueSpan: MIN_VALUE_SPAN_FOR_CHART_ZOOMING[options?.granularity],
        bottom: 5,
      },
    ],
    // @TODO: Add support
    // dataLabels: {
    //   ...(options?.stackingConfig?.relativeToLi !==undefined ? {
    //         formatter: function(val, opts) {
    //           return getRelativePercentageText(options?.stackingConfig?.relativeToLi ?? "", +val, opts);
    //         }
    //       }:
    //       {enabled: false})
    // },
    yAxis: Array(chartYAxisCount).fill(0).map((_, idx) => { return {
      type: 'value',
      alignTicks: true,
      axisLine: {
        show: true,
      },
      offset: idx<2 ? 0 : 20*(idx),
      axisLabel: {
        formatter: function (value: number, index: number) {
          const valueTypeForItem = yAxisValueTypes?.[idx] ?? defaultValueType;
          return valueFormatter(value, valueTypeForItem as FinancialValueType);
        }
      }
    }}),
    xAxis: {
      type: 'time',
      ...(options.dateFormat ? {
        axisLabel: {
          formatter: options.dateFormat,
        }
      }:{}),
    },
    legend: {
      bottom: 40,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: chartYAxisCount >1 ? 'cross': 'shadow',
      },
      formatter: function (params){
          if(Array.isArray(params)){
            return params.map((param, idx) => {
              const { seriesIndex, data, marker } = param;
              // @ts-ignore
              const formattedValue = getFormattedValue(data?.[1] as number, seriesIndex as number);
              const valueTooltip = `<div style="display: flex; justify-content: space-between; width: 100%; gap: 10px;"><span style="flex: 1; text-align: left;">${marker}${param.seriesName}</span> <span style="flex: 1; text-align: right;"><b>${formattedValue}</b></span></div>`
              if(idx===0){
                // @ts-ignore
                const formattedDate = formatDateByTimeUnit(new Date(data?.[0] as number), options?.granularity ?? "days");
                return `${formattedDate}<br/>${valueTooltip}`;
              }
              return valueTooltip;
            }).join('');
          } else {
            const { seriesIndex, data, marker } = params;
            // @ts-ignore
            const formattedValue = getFormattedValue(data?.[1] as number, seriesIndex as number);
            return `<div style="display: flex; justify-content: space-between; width: 100%; gap: 10px;"><span style="flex: 1; text-align: left;">${marker}${params.seriesName}</span> <span style="flex: 1; text-align: right;"><b>${formattedValue}</b></span></div>`;
          }
        //   // @TODO: Add support
        //   // const relativeLiName = options?.stackingConfig?.relativeToLi;
        //
        //   // if(relativeLiName!==undefined){
        //   //   const relativePercentage = getRelativePercentageText(relativeLiName, value, parameters);
        //   //   if(relativePercentage !==""){
        //   //     tooltip = `Value: ${tooltip} | (% of ${relativeLiName}): ${relativePercentage}`
        //   //   }
        //   // }
        //   return tooltip;
      },
    },
  };

  return <Segment>
    <ChartRenderer options={{...defaultOptions, series}}
  /></Segment>


}

function ChartRenderer({options}: {
  options: EChartConfigurableOptions}){
  const chartRef = useECharts(options);

  return <div ref={chartRef}
              style={{ width: '100%', height: '300px' }} />;
}

function getRelativePercentageText(relativeLiName: string, val: number, opts: any): string{
  const relatedLiSeriesIndex = opts.w.globals.seriesNames.findIndex((s:string)=>s === relativeLiName);
  if(opts.seriesIndex === relatedLiSeriesIndex || relatedLiSeriesIndex === -1){
    return "";
  }
  const relatedDataPoint = opts.w.globals.series[relatedLiSeriesIndex][opts.dataPointIndex];
  if(relatedDataPoint === 0){
    return "- %";
  }
  return (val / relatedDataPoint * 100).toFixed(2) + '%';
}

const MIN_VALUE_SPAN_FOR_CHART_ZOOMING: Record<TimeUnits, number> = {
  seconds: 1000 * 2, // 2 seconds
  minutes: 60 * 1000 * 2, // 2 minutes
  hours: 3600 * 1000 * 2, // 2 hours
  days: 3600 * 24 * 1000 * 2, // 2 days
  weeks: 3600 * 24 * 1000 * (7*2), // 14 days i.e. 2 weeks
  months: 3600 * 24 * 1000 * (30*2), // 60 days i.e. 2 months
  quarters: 3600 * 24 * 1000 * (90*2), // 180 days i.e. 2 quarters
  years: 3600 * 24 * 1000 * 365, // 365 days
};