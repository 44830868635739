import {
  addQuarters,
  addSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  differenceInQuarters,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  getDaysInMonth,
  parse,
  subSeconds,
  format as format2,
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
  differenceInCalendarYears,
  differenceInCalendarWeeks, differenceInCalendarDays, differenceInCalendarISOWeeks,
} from "date-fns";

import {DomainError, LogicError} from "./exceptions";
import {equals, keys, last} from "ramda";
import {FinancialDate} from "../ps-types/FinancialDate";
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addTime,
  addWeeks,
  addYears,
  dayTruncateDate,
  TimeUnitValues,
  truncateTime
} from "./TimeGranularity";
import {buildUTCDate, TimeIncrement, TimeRange, TimeUnit, TimeUnits, utcDate} from "./Time.types";
import { utcToZonedTime, format } from "date-fns-tz";


export class TimeStream<T> {
    timeStream: Record<number, T> = {};

    add(time: TimeUnit, value: T) {
        this.timeStream[time] = value;
        return this;
    }

    get(time: number): T | undefined {
        return this.timeStream[time]
    }

    getValues() {
      return Object.values(this.timeStream);
    }

    getTimes() {
      return Object.keys(this.timeStream).map(k => parseInt(k));
    }

    serialize(): Record<number, T> {
        return this.timeStream
    }

    static deserialize<K>(timeStream: any) {
      const ts = new TimeStream<K>()
      ts.timeStream = timeStream || {}
      return ts
    }

    /**
     * This method returns a timeStream whose keys are timestamps instead of timeIndex's relative numbers
     * @param timeIndex
     */
    toTimedTimeStream(timeIndex: TimeIndex) {
        let timedTimeStream = new TimeStream<T>();
        for (const time of keys(this.timeStream)) {
            timedTimeStream.add(timeIndex.getDate(time).getTime(), this.timeStream[time])
        }
        return timedTimeStream;
    }

    fromTimedTimeStream(timeIndex: TimeIndex) {
       let relativeTimeStream = new TimeStream<T>()

        for (const time of keys(this.timeStream)) {
          relativeTimeStream.add(timeIndex.timeDelta(new Date(parseInt(time as any))) , this.timeStream[time])
        }
        return relativeTimeStream;
    }

  getRange(range: TimeRange, granularity: TimeUnits) {

      let values: T[] = [];

      for(let time = range.start; time < range.end;
          time = addTime(new Date(time), 1, granularity).getTime()) {
         if(this.timeStream[time]) {
           values.push(this.timeStream[time])
         }
      }

      return values;
  }

  clone()  {
    return TimeStream.deserialize(this.serialize())
  }
}

export function equalTimeIncrements(left: TimeIncrement, right: TimeIncrement) {
    return left.unit === right.unit && left.value === right.value;
}

export function isLeftTimeIncrementWider(left: TimeIncrement, right: TimeIncrement) {
    if (left.unit === right.unit) {
        return left.value > right.value
    }

    for (const value of TimeUnitValues) {
        if (left.unit === value) {
            return false
        } else if (right.unit === value) {
            return true
        }
    }
    throw new LogicError('There must be a matching TimeUnit value')
}

export type TimeAggregation = 'add' | 'last' | 'first'

export interface TimeAggregationDefinition {
    aggregationTechniqueReference: 'calendar' | 'operations',
    timeAggregationOverrides: Record<string, TimeAggregation>
}

export function buildTimeIncrement(unit: TimeUnits, value: TimeUnit): TimeIncrement {
    return {unit, value}
}

export interface TimeUnitView {
  time: TimeUnit,
  date: FinancialDate,
  fDate: string,
}

export interface TimeResolution {
    startDate: FinancialDate,
    endDate: FinancialDate,
    increment: TimeIncrement,
    _format: string
}

export type TimeLine = TimeUnitView[];


export const addDateWithUnit: Record<TimeUnits, typeof addYears> = {
    years:  addYears,
    quarters: addQuarters,
    months: addMonths,
    weeks: addWeeks,
    days: addDays,
    hours: addHours,
    minutes: addMinutes,
    seconds: addSeconds,
}

export const differencesWithUnit:  Record<TimeUnits, typeof differenceInYears> = {
  years:  differenceInCalendarYears,
  quarters: differenceInCalendarQuarters,
  months: differenceInCalendarMonths,
  weeks: differenceInCalendarWeeks,
  days:differenceInCalendarDays,
  hours: differenceInHours,
  minutes: differenceInHours,
  seconds: differenceInSeconds
}

function differenceInMonthsUTC(from: Date | number, to: Date | number) {
  from = new Date(from)
  to = new Date(to)
  return from.getUTCMonth() - to.getUTCMonth() + (12 * (from.getUTCFullYear() - to.getUTCFullYear()));
}


export type TimeUnitFormats = Record<TimeUnits, string>;

export const DEFAULT_DATE_FORMATS: TimeUnitFormats = {
  years: "y",
  quarters: "QQQ y",
  months: "MMMM y",
  weeks: "w Y",
  days: "MMMM/d/y",
  hours: "MMMM/d/y hh:00 aaa",
  minutes: "MMMM/d/y hh:mm aaa",
  seconds: "MMMM/d/y hh:mm:ss aaa",
}

export const SINGLE_VALUE_DATE_FORMATS: TimeUnitFormats = {
  years: "yy",
  quarters: "QQQ",
  months: "MMM",
  weeks: "w",
  days: "d",
  hours: "hh:00",
  minutes: "hh:mm aaa",
  seconds: "hh:mm:ss aaa",
}

export function formatDateByTimeUnit(date: FinancialDate, timeUnits: TimeUnits) {
  return formatAsUTC(date, DEFAULT_DATE_FORMATS[timeUnits]);
}

const resetDateFromFormat = (date: FinancialDate, formatStr: string): FinancialDate => new Date(date);


// +validate
export interface TimeIndexDto {
    startDate: number
    endDate: number
    increment: TimeIncrement
    format: string
    useRawDatesToBuildIndex: boolean
}

export class TimeIndex implements TimeResolution {

    public _timeLine: TimeLine;
    public startDate: FinancialDate;
    public endDate: FinancialDate;

    constructor(
        startDate: FinancialDate,
        endDate: FinancialDate,
        readonly increment: TimeIncrement,
        readonly _format: string,
        readonly _useRawDatesToBuildIndex = false,
    ) {
        this.startDate = this.buildStartDate(startDate)
        this.endDate = this.buildEndDate(endDate)
        this._timeLine = this.buildTimeline();
    }

    isEqualTo(timeIndex: TimeIndex) {
        return this.startDate.getTime() === timeIndex.startDate.getTime()
            && this.endDate.getTime() === timeIndex.endDate.getTime()
            && equalTimeIncrements(this.increment, timeIndex.increment)
            && this._format === timeIndex._format
            && this._useRawDatesToBuildIndex === timeIndex._useRawDatesToBuildIndex
    }

    dateIsAfterStartDate(date: FinancialDate) {
      switch (this.increment.unit) {
        case "years":
          return differenceInYears(date, this.startDate) >= 0
        case "quarters":
          return differenceInQuarters(date, this.startDate) >= 0
        case "months":
          return differenceInMonths(date, this.startDate) >= 0
        case "weeks":
          return differenceInWeeks(date, this.startDate) >= 0
        case "days":
          return differenceInDays(date, this.startDate) >= 0
        case "hours":
          return differenceInHours(date, this.startDate) >= 0
      }
      return date >= this.startDate
    }

    dateIsOutsideOfTimeIndexRange(date: FinancialDate) {
        return date < this.startDate || date > this.endDate;
    }

    private buildStartDate(date: FinancialDate): FinancialDate {
       return  truncateTime(this.increment.unit, date);
    }

    private buildEndDate(date: FinancialDate): FinancialDate {
        //adds a unit to the end date so that the end date is included in the time index
        return ceilDate(this.increment.unit, date);
    }

    clone() {
        return new TimeIndex(
          new Date(this.startDate),
          new Date(this.endDate),
          this.increment,
          this._format,
          this._useRawDatesToBuildIndex
        )
    }

    getUnit(): TimeUnits {
      return this.increment.unit
    }

    startDatesDifference(otherTimeIndex: TimeIndex) {
      if(!equals(otherTimeIndex.increment, this.increment)) {
        throw new Error(`Time indexes must be aligned for 
        this comparison to make sense, got  ${JSON.stringify(otherTimeIndex.increment)} and ${JSON.stringify(this.increment)} `)
      }
      return this.timeUnitDifference(this.startDate, otherTimeIndex.startDate);
    }

    public timeUnitDifference(from: FinancialDate, to: FinancialDate) {
      from = truncateTime(this.increment.unit, from)
      to = truncateTime(this.increment.unit, to)

      if(to < from) {
        return -1*differencesWithUnit[this.increment.unit](from, to)
      }
      return differencesWithUnit[this.increment.unit](to, from)
    }

    withTimeIncrement(increment: TimeIncrement, formats = DEFAULT_DATE_FORMATS, useProvidedDatesAsIs = false): TimeIndex {
        return new TimeIndex(
        this.startDate,
        this.endDate,
        increment,
        formats[increment.unit],
        useProvidedDatesAsIs,
        );
    }

    withFormat(format: string): TimeIndex {
      return new TimeIndex(this.startDate, this.endDate, this.increment, format)
    }

    withDates(from: FinancialDate, to: FinancialDate, useProvidedDatesAsIs= false) {
      return new TimeIndex(from, to, this.increment, this._format, useProvidedDatesAsIs)
    }

    withStartDate(date: FinancialDate) {
      return new TimeIndex(date, this.endDate, this.increment, this._format)
    }

    withEndDate(date: FinancialDate) {
      return new TimeIndex(this.startDate, date, this.increment, this._format);
    }

    moveStartDate(newStartDate: FinancialDate) {
      const difference = this.timeUnitDifference(this.startDate, this.endDate);

      this.startDate = this.buildStartDate(newStartDate)
      this.endDate = this.buildEndDate(addDateWithUnit[this.increment.unit](newStartDate, difference))
      this._timeLine = this.buildTimeline();
    }

    get timeLine() {
      return this._timeLine;
    }

    getDate(index: number){

      const { unit, value } = this.increment;
      let d = truncateTime(unit, this.startDate);

      return addTime(d,   value * index, unit);
    }

    startDateFormatted(){
      return this.timeLine[0].fDate;
    }

    withGranularity(granularity: TimeUnits) {
      return this.withTimeIncrement({unit: granularity, value: 1})
    }

    endDateFormatted(){
      return last(this.timeLine)!.fDate;
    }

    buildTimeline(to = this.endDate): TimeLine {
      const timeLine = []
      for (let t = 0, d = this.startDate; d <= to; t+=1, d = this.incrementDate(d)) {
        timeLine.push(
          {time: t, date: d, fDate: formatAsUTC(d, this._format)}
        )
      }
      return timeLine;
    }

    private incrementDate(date: FinancialDate) {
        const { unit, value } = this.increment

        return addTime(date, value, unit);
    }

    getTimeLength() {
      return this.timeLine.length;
    }

    timeDelta(date: FinancialDate) {
      return this.timeUnitDifference(this.startDate, date);
    }

    length() {
      return this.timeLine.length;
    }

    serialize(): TimeIndexDto {
      return {
        startDate: this.startDate.getTime(),
        endDate: this.endDate.getTime(),
        increment: this.increment,
        format: this._format,
        useRawDatesToBuildIndex: this._useRawDatesToBuildIndex,
      }
    }

  static deserialize(timeIndex: TimeIndexDto) {
    return new TimeIndex(
      new Date(timeIndex.startDate),
      new Date(timeIndex.endDate ),
      timeIndex.increment,
      timeIndex.format,
      timeIndex.useRawDatesToBuildIndex
    )
  }

  //@Deprecated DO not use this function when using timed line items
  getDaysInMonth(t: TimeUnit) {
    return getDaysInMonth(this.getDate(t));
  }

  getRange() {
    return {start: this.startDate.getTime(), end: this.endDate.getTime()}
  }



  sliced(slice: number, size: number) {

    let timeLine = this.timeLine;

    //create Chunks
    let chunkSize = size;
    let chunks: TimeUnitView[][] = [];

    for(let i = 0; i < timeLine.length; i += chunkSize) {
      chunks.push(timeLine.slice(i, i + chunkSize));
    }

    let timeIndexes = chunks.map((chunk) =>
      buildTimeIndex(chunk[0].date, chunk[chunk.length - 1].date, this.getUnit())
    );

    return timeIndexes[slice];
  }
}

export const findDiffBetweenDates = (d1: FinancialDate, d2: FinancialDate): [TimeUnits, number] => {
    const entries: [TimeUnits, number][] = TimeUnitValues.map(unit => [unit, differencesWithUnit[unit as TimeUnits](d2, d1)])
    const [unit, value] = entries.reverse().find(([_, v]) => v >= 1)!
    return [unit, value];
}

export const  MONTHLY_TIME_INDEX = new TimeIndex(
  new Date("January 05, 2022"),
  new Date("June 05, 2022"),
  buildTimeIncrement("months", 1),
  "MMMM",
  true
);

export const ZERO_TIME_INDEX = new TimeIndex(
  new Date("January 05, 2022"),
  new Date("January 04, 2022"),
  buildTimeIncrement("days", 1),
  "MMMM",
  true
);

export function buildTimeIndex(startDate: FinancialDate, endDate: FinancialDate, unit: TimeUnits, _useRawDates=false) {
  switch (unit) {
    case "hours":
      return buildHourlyTimeIndex(startDate, endDate, _useRawDates);
    case "days":
      return buildDailyTimeIndex(startDate, endDate, _useRawDates);
    case "months":
      return buildMonthlyTimeIndex(startDate, endDate, _useRawDates);
    case "years":
      return buildAnnualTimeIndex(startDate, endDate, _useRawDates);
    case "weeks":
      return buildWeeklyTimeIndex(startDate, endDate, _useRawDates);
    case "quarters":
        return buildQuarterlyTimeIndex(startDate, endDate, _useRawDates);
    default:
      throw new Error(`Unsupported time unit ${unit}`);
  }
}

export const buildMonthlyTimeIndex = (startDate: FinancialDate, endDate: FinancialDate,_useRawDates = false) => {
  if(!_useRawDates){
      startDate = truncateTime("months", startDate);
  }

  return new TimeIndex(
    startDate,
    endDate,
    buildTimeIncrement("months", 1),
    DEFAULT_DATE_FORMATS["months"],
    true
  )
}

export const buildAnnualTimeIndex = (startDate: FinancialDate, endDate: FinancialDate, _useRawDates = false) => {
    if(!_useRawDates){
      startDate = truncateTime("years", startDate);
    }
  return new TimeIndex(
    startDate,
    endDate,
    buildTimeIncrement("years", 1),
    "y",
    true
  )
}



export const buildWeeklyTimeIndex = (startDate: FinancialDate, endDate: FinancialDate, _useRawDates = false) => {
    if(!_useRawDates){
        startDate =  truncateTime("weeks", startDate);
    }

  return new TimeIndex(
    startDate,
    endDate,
    buildTimeIncrement("weeks", 1),
    "w Y",
    true
  )
}

export const buildQuarterlyTimeIndex = (startDate: FinancialDate, endDate: FinancialDate, _useRawDates = false) => {
    if(!_useRawDates){
      startDate =  truncateTime("quarters", startDate);
    }

    return new TimeIndex(
        startDate,
        endDate,
        buildTimeIncrement("quarters", 1),
        DEFAULT_DATE_FORMATS['quarters'],
        true
    )
}


export const buildDailyTimeIndex = (startDate: FinancialDate, endDate: FinancialDate, _useRawDates = false) => {

  return new TimeIndex(
   _useRawDates ? startDate : dayTruncateDate(startDate),
    endDate,
    buildTimeIncrement("days", 1),
    "MMMM/d/y",
    true
  )
}

export const buildHourlyTimeIndex = (startDate: FinancialDate, endDate: FinancialDate,  _useRawDates = false) => {
  if(!_useRawDates) {
    startDate =  truncateTime("hours", startDate);
  }
  return new TimeIndex(
    startDate,
    endDate,
    buildTimeIncrement("hours", 1),
    "MMMM/d/y hh:00 aaa",
    true
  )
}

export function timeUnitsDifference(from: Date, to: Date, granularity: TimeUnits) {
  from = truncateTime(granularity, from)
  to = truncateTime(granularity, to)

  if(to < from) {
    return -1*differencesWithUnit[granularity](from, to)
  }
  return differencesWithUnit[granularity](to, from)
}

export function getLastDateOfPreviousMonth(): number {
    const todaysDate = new Date();
    const firstDayCurrentMonth = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1);
    const lastDayPreviousMonth = new Date(firstDayCurrentMonth.setDate(0));
    return lastDayPreviousMonth.getTime();
}

export function getLastDateOfCurrentMonthInUTC(): Date {
    const todaysDateObject = new Date();
    return utcDate(todaysDateObject.getUTCFullYear(), todaysDateObject.getUTCMonth()+1, 0);
}

export function getFirstDateForCurrentMonthInUTC(): Date {
    const todaysDateObject = new Date();
    return utcDate(todaysDateObject.getUTCFullYear(), todaysDateObject.getUTCMonth(), 1);
}
/**
  * THIS IS A GENERATED SECTION, DON'T MANUALLY EDIT ANYTHING BELOW THIS POINT
  *
  **/

export function validateTimeIncrement(dto: any, exceptionClass: new(msg: string) => Error = Error): TimeIncrement {
   if (dto === null || typeof dto !== 'object' || Array.isArray(dto)) throw new exceptionClass('Expected TimeIncrement to be "object": ' + JSON.stringify(dto));
   if (validateTimeUnits(dto.unit, exceptionClass) === undefined) throw new exceptionClass('Expected TimeIncrement.unit to be "TimeUnits": ' + JSON.stringify(dto.unit));
   if (validateTimeUnit(dto.value, exceptionClass) === undefined) throw new exceptionClass('Expected TimeIncrement.value to be "TimeUnit": ' + JSON.stringify(dto.value));
   return dto as TimeIncrement
}

export function validateTimeIndexDto(dto: any, exceptionClass: new(msg: string) => Error = Error): TimeIndexDto {
   if (dto === null || typeof dto !== 'object' || Array.isArray(dto)) throw new exceptionClass('Expected TimeIndexDto to be "object": ' + JSON.stringify(dto));
   if (typeof dto.startDate !== 'number') throw new exceptionClass('Expected TimeIndexDto.startDate to be "number": ' + JSON.stringify(dto.startDate));
   if (typeof dto.endDate !== 'number') throw new exceptionClass('Expected TimeIndexDto.endDate to be "number": ' + JSON.stringify(dto.endDate));
   if (validateTimeIncrement(dto.increment, exceptionClass) === undefined) throw new exceptionClass('Expected TimeIndexDto.increment to be "TimeIncrement": ' + JSON.stringify(dto.increment));
   if (typeof dto.format !== 'string') throw new exceptionClass('Expected TimeIndexDto.format to be "string": ' + JSON.stringify(dto.format));
   if (typeof dto.useRawDatesToBuildIndex !== 'boolean') throw new exceptionClass('Expected TimeIndexDto.useRawDatesToBuildIndex to be "boolean": ' + JSON.stringify(dto.useRawDatesToBuildIndex));
   return dto as TimeIndexDto
}

export function validateTimeUnit(alias: any, exceptionClass: new(msg: string) => Error = Error): TimeUnit {
   if (typeof alias !== 'number') throw new exceptionClass('Expected TimeUnit to be "number": ' + JSON.stringify(alias));
   return alias as TimeUnit
}

export function validateTimeUnits(alias: any, exceptionClass: new(msg: string) => Error = Error): TimeUnits {
   if ((alias !== "minutes") && (alias !== "hours") && (alias !== "days") && (alias !== "weeks") && (alias !== "months") && (alias !== "quarters") && (alias !== "years")) throw new exceptionClass('Expected TimeUnits to be ""minutes" | "hours" | "days" | "weeks" | "months" | "quarters" | "years"": ' + JSON.stringify(alias));
   return alias as TimeUnits
}

/**
 * Ceil Date is the oposite as TruncateDate (Which is more similar to floor)
 * @param unit
 * @param date
 */
export function ceilDate(unit: TimeUnits, date: Date) {
  let newDate = addTime(truncateTime(unit, date), 1, unit)
  newDate = subSeconds(newDate, 1)
  return newDate;
}

/**
 * Converts a UTC date to a local date
 * @param utcDate
 */
export function formatAsUTC(date: Date, formatStr: string) {
  return format(utcToZonedTime(date, 'UTC'),
    formatStr,
    { timeZone: 'UTC' });
}


export function getEpochFromFormattedUTCString(dateString: string, formatStr: string): number {
    const parsedDate = parse(dateString, formatStr, new Date());
    const parsedDateInUTC = buildUTCDate(parsedDate)
    return parsedDateInUTC.getTime();
}

export function* getRange(range: TimeRange, granularity: TimeUnits) {
  for(let time = range.start; time < range.end;
      time = addTime(new Date(time), 1, granularity).getTime()) {
    yield time;
  }
}

export function getTimeUnitIndex(date: Date, unit: TimeUnits) {
  let d = truncateTime(unit, date);
  let start = truncateTime(unit, new Date(d));
  switch (unit) {
    case "quarters":
      return Math.floor(d.getUTCMonth() / 3);
    case "months":
      return d.getUTCMonth();
    case "weeks":
      //Get week of the year
      return differenceInCalendarISOWeeks(d, start);
    case "days":
      return d.getUTCDate()
  }

  throw new DomainError(`Unsupported time unit for Index calculation ${unit}`);
}


// DON'T ADD ANY CODE BELOW THIS POINT, NOR PAST THE GENERATED SECTION HEADER ABOVE
