import {GridToolbar} from "@mui/x-data-grid";
import {DataGridPro} from "@mui/x-data-grid-pro";
import React, {useEffect, useState} from "react";
import {getNewProjects, getSalesforceConfigKeys} from "./whatIfScenarios.client";
import {useCompanyId} from "../../core";
import {AmProject, ProjectVersion} from "../../ps-types";
import {SmallLoading} from "../../ui/Loading";


export function NewProjectSelection({onSelection, selectedProjects}: {
  selectedProjects: ProjectVersion[],
  onSelection: (selectedSalesforceIds: ProjectVersion[], newProjects: AmProject[]) => void}) {

  const [newProjects, setNewProjects] = useState<Record<string, {name: string}>>({});

  const [selected, setSelected] = useState(selectedProjects);

  const [loading, setLoading] = useState(true);

  const companyId = useCompanyId();


  const handleSelectedProjectIds = (selectedIds: string[]) => {
    const newSelected = selectedIds.map(id => ({
      projectId: id,
      versionId: '[new]'
    }));

    setSelected(newSelected);

    const projects = newSelected.map(pv => {
      return {
        id: pv.projectId,
        name: newProjects[pv.projectId].name,
        defaultVersion: "[new]",
        versions: [{versionId: "[new]"}]
      } as AmProject
    });

    onSelection(newSelected, projects);
  }

  useEffect(() => {
    async function load() {

      const configKey = (await getSalesforceConfigKeys(companyId))[0];

      getNewProjects(companyId, configKey).then((res) => {
        console.log(res)
        setNewProjects(res);
        setLoading(false);
      })
    }

    load().catch(e => {
      setLoading(false);
      console.error(e)
    });

  }, []);


  const rows = Object.entries(newProjects).map(([id, project]) => {
    return {
      id,
      ...project
    }
  });

  const columns = [
    { field: 'id', headerName: 'ID', width: 200 },
    { field: 'name', headerName: 'Name', width: 300 },
  ];

  return <div style={{width: "100%"}}>
    {loading && <SmallLoading/>}
    {!loading && <DataGridPro
    density={'compact'}
    rows={rows}
    columns={columns}
    pagination={true}
    initialState={{
      pagination: {
        paginationModel: {
          pageSize: 10,
        },
      }
    }}
    autoHeight
    columnBuffer={2} columnThreshold={2}
    slots={{ toolbar: GridToolbar }}
    slotProps={{
      toolbar: {
        showQuickFilter: true,
        quickFilterProps: { debounceMs: 500 },
      }
    }}
    checkboxSelection
    disableRowSelectionOnClick
    rowSelectionModel={selected.map(pv => pv.projectId)}
    onRowSelectionModelChange={(selection) => {
      handleSelectedProjectIds(selection as string[])
    }}
  />}</div>


}