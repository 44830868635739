import DatePicker from "react-datepicker";
import React, {useEffect, useMemo, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRange.css"
import {LineItemsStore} from "../ps-models/lineitems-store";
import {
  buildUTCDate,
  getLastDateOfPeriodInUTC,
  TimeIndex,
  TimeUnits,
  truncateTime,
  utcDate
} from "../ps-models";
import {Select} from "semantic-ui-react";
import {DateRangeType} from "../ps-types";

export function useDateRange(store?: LineItemsStore, selectorGranularity: 'month' | 'month-old' | 'day' = 'day'): [JSX.Element, DateRangeType] {
  let [dateRange, setDateRange] = React.useState<DateRangeType>( () => {
    if (store != null) {
      return {from: store.timeIndex.startDate, to: store.timeIndex.endDate}
    } else {
      return {from: new Date(), to: new Date()}
    }
  });
  useEffect(() => {
    if (store != null) {
      if(selectorGranularity === 'month'){
        let startDateAsPerTimeIndex = store.timeIndex.startDate;

        setDateRange({
          from: startDateAsPerTimeIndex,
          to: getLastDateOfTheMonth(startDateAsPerTimeIndex)
        });
        return;
      } else if(selectorGranularity === 'month-old'){
        let initialStartDate = store.timeIndex.startDate;
        let lastDateOfFirstMonth = new Date(new Date(initialStartDate.setDate(1)).setFullYear(initialStartDate.getFullYear(), initialStartDate.getMonth()+1, initialStartDate.getDate()-1))

        setDateRange({
          from: new Date(initialStartDate.setDate(1)),
          to: lastDateOfFirstMonth
        });
        return;
      }
      setDateRange({
        from: store.timeIndex.startDate,
        to: store.timeIndex.endDate
      });
    }
  }, [store]);

  if(!store) {
    return [<div>...loading</div>, dateRange];
  }

  return [
    <DateRange
      initialStartDate={store.timeIndex.startDate}
      initialEndDate={store.timeIndex.endDate}
      onSelect={(from, to) => {
        setDateRange({from, to})
      }}
      selectorGranularity={selectorGranularity}
    />,
    dateRange
  ]
}

export function DateRange({onSelect, minDate, maxDate, initialStartDate, initialEndDate, selectorGranularity, layout = "horizontal"}: {
  initialStartDate: Date,
  initialEndDate: Date,
  minDate?: Date,
  maxDate?: Date,
  onSelect: (startDate: Date, endDate: Date) => void,
  selectorGranularity?: 'month' | 'month-old' | 'day',
  layout?: "horizontal" | "vertical"
})
   {

   if(!selectorGranularity){
     selectorGranularity = 'day';
   }

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const handleDateChange = (curStartDate: Date, curEndDate: Date) => {
    console.info("Date change!", curStartDate, curEndDate)
    if(curStartDate && curEndDate) {
      onSelect(curStartDate, curEndDate);
    }
  }

  return (
    <div className={`date-range ${layout === "vertical" ? "vertical": ""}`}>
      {(selectorGranularity==='month' || selectorGranularity==='month-old') && <div className="date-range-from">
        <span className="date-range-label">Month</span>
        <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date!)
              if(date) {
                if(selectorGranularity === 'month-old'){
                  let lastDateOfMonth = new Date(new Date(date).setFullYear(date.getFullYear(), date.getMonth()+1, date.getDate()-1))
                  onSelect(date, lastDateOfMonth);
                } else {
                  onSelect(date, getLastDateOfTheMonth(date));
                }
              }
            }}
            minDate={initialStartDate}
            maxDate={initialEndDate}
            showMonthYearPicker
            dateFormat={'MMMM yyyy'}
        />
      </div>}
      {selectorGranularity==='day' && <><div className="date-range-from">
            <span className="date-range-label" >From</span>
            <DatePicker
              selected={startDate}
              onChange={(date) => { setStartDate(date!); handleDateChange(date!, endDate)  } }
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div>
          <div className="date-range-to">
            <span className="date-range-label">To</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => { setEndDate(date!); handleDateChange(startDate, date!) }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={minDate}
              maxDate={maxDate}
            />
          </div></>}
    </div>
  );

}

export function SingleDatePicker({onSelect, minDate, maxDate, initialStartDate, useUTC = false}: {
  initialStartDate: Date,
  minDate: Date,
  maxDate: Date,
  onSelect: (startDate: Date) => void,
  useUTC?: boolean
})
{
  const [startDate, setStartDate] = useState<Date>();

  useEffect(()=>{
    setStartDate(initialStartDate);
  }, [initialStartDate])

  const handleDateChange = (curStartDate: Date) => {
    console.info("Date change!", curStartDate)
    if(curStartDate) {
      onSelect(curStartDate);
    }
  }

  const getLocalDateFromUTC = (utcDate: any) => {
    if (!utcDate) return null;
    return new Date(
        utcDate.getUTCFullYear(),
        utcDate.getUTCMonth(),
        utcDate.getUTCDate(),
        utcDate.getUTCHours(),
        utcDate.getUTCMinutes(),
        utcDate.getUTCSeconds()
    );
  };

  return (
          <DatePicker
              selected={useUTC ? getLocalDateFromUTC(startDate) : startDate}
              onChange={(dateChosen) => {
                let date = useUTC ? buildUTCDate(dateChosen!) : dateChosen;
                setStartDate(date!);
                handleDateChange(date!)
              } }
              selectsStart
              startDate={startDate}
              minDate={minDate}
              maxDate={maxDate}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              todayButton={"Jump to Today"}
          />
  );
}

// @TODO: Only supports Months/Quarters/Years. Add support for more
export function GranularitySelector({store, onSelectionChange, initialSelection, allowNoneSelection = false}: {store: LineItemsStore, initialSelection?: TimeUnits | undefined, onSelectionChange: (selectedGranularity: TimeUnits | undefined)=>void, allowNoneSelection?: boolean}){
  let [granularity, setGranularity] = useState<TimeUnits | undefined>(initialSelection ?? (allowNoneSelection ? undefined: "months"));

  const storeTimeIndexUnit = store.timeIndex.getUnit();
  // @TODO: This would have to support more granularities. Currently only supports granularities greater than month.
  let granularityOptions  = useMemo(()=>{
    if(storeTimeIndexUnit){
      if(storeTimeIndexUnit === 'months'){
        return [
            ...allowNoneSelection ? [{ key: 'none', text: 'None', value: undefined }] : [],
          {key: 'months', text: 'Month', value: 'months'},
          {key: 'quarters', text: 'Quarter', value: 'quarters'},
          {key: 'years', text: 'Year', value: 'years'},
        ]
      } else if(storeTimeIndexUnit === 'quarters'){
        return [
          ...allowNoneSelection ? [{ key: 'none', text: 'None', value: undefined }] : [],
          {key: 'quarters', text: 'Quarter', value: 'quarters'},
          {key: 'years', text: 'Year', value: 'years'},
        ]
      } else if(storeTimeIndexUnit === 'years'){
        return [
          ...allowNoneSelection ? [{ key: 'none', text: 'None', value: undefined }] : [],
          {key: 'years', text: 'Year', value: 'years'},
        ]
      }
    }
    return [
      ...allowNoneSelection ? [{ key: 'none', text: 'None', value: undefined }]: [],
      {key: 'months', text: 'Month', value: 'months'},
      {key: 'quarters', text: 'Quarter', value: 'quarters'},
      {key: 'years', text: 'Year', value: 'years'},
    ]
  }, [storeTimeIndexUnit, allowNoneSelection])
  return <Select
      placeholder="Select Granularity"
      value={granularity}
      options={granularityOptions}
      onChange={(e, data) => {
        let selected = data.value as "months" | "quarters" | "years" | undefined;
        setGranularity(selected);
        onSelectionChange(selected);
      }}
  />
}

export function useGranularUTCDateSelector(store: LineItemsStore, rangeSelection: boolean = false): [JSX.Element, DateRangeType] {
  let [granularity, setGranularity] = useState<"months" | "quarters" | "years">("months");
  let [dateSelectorComponent, dateRange] = useUTCDateSelector(granularity, store, rangeSelection);

  const storeTimeIndexUnit = store.timeIndex.getUnit();
  // @TODO: This would have to support more granularities. Currently only supports granularities greater than month.
  let granularityOptions  = useMemo(()=>{
    if(storeTimeIndexUnit){
      if(storeTimeIndexUnit === 'months'){
        return [
          {key: 'months', text: 'Month', value: 'months'},
          {key: 'quarters', text: 'Quarter', value: 'quarters'},
          {key: 'years', text: 'Year', value: 'years'},
        ]
      } else if(storeTimeIndexUnit === 'quarters'){
        return [
          {key: 'quarters', text: 'Quarter', value: 'quarters'},
          {key: 'years', text: 'Year', value: 'years'},
        ]
      } else if(storeTimeIndexUnit === 'years'){
        return [
          {key: 'years', text: 'Year', value: 'years'},
        ]
      }
    }
    return [
      {key: 'months', text: 'Month', value: 'months'},
      {key: 'quarters', text: 'Quarter', value: 'quarters'},
      {key: 'years', text: 'Year', value: 'years'},
    ]
  }, [storeTimeIndexUnit])

  return [<div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'flex-start', gap: '5px',
    border: '1px dotted gray', width:'35%',padding: '5px 5px', marginBottom: '10px'
  }}>
    <strong>Date Range Selector</strong>
    <Select
        placeholder="Range Granularity"
        value={granularity}
        options={granularityOptions}
        onChange={(e, data) => {
          setGranularity(data.value as "months" | "quarters" | "years");
        }}
    />
    {dateSelectorComponent}
  </div>, dateRange]
}


// This can be used independently as well, to have date selector with fixed granularity.
export function useUTCDateSelector(granularity: TimeUnits, store?: LineItemsStore, rangeSelection: boolean = false): [JSX.Element, DateRangeType] {
  let [dateRange, setDateRange] = React.useState<DateRangeType>( () => {
    if (store != null) {
      return {from: store.timeIndex.startDate, to: getLastDateOfPeriodInUTC(rangeSelection ? store.timeIndex.endDate: store.timeIndex.startDate, granularity)}
    } else {
      return {from: getFirstDateOfPeriodInUTC(new Date(), granularity), to: getLastDateOfPeriodInUTC(new Date(), granularity)}
    }
  });
  // useEffect(() => {
  //   if (store != null) {
  //     let startDateAsPerTimeIndex = store.timeIndex.startDate;
  //     let endDateAsPerTimeIndex = rangeSelection ? store.timeIndex.endDate: startDateAsPerTimeIndex;
  //     let dateRangeEndsAt = getLastDateOfPeriodInUTC(endDateAsPerTimeIndex, granularity)
  //     if(dateRange.from === startDateAsPerTimeIndex && dateRange.to === dateRangeEndsAt){
  //       return;
  //     } else {
  //       setDateRange({
  //         from: startDateAsPerTimeIndex,
  //         to: dateRangeEndsAt
  //       });
  //     }
  //     return;
  //   }
  // }, [store, granularity, rangeSelection]);

  if(!store) {
    return [<div>...loading</div>, dateRange];
  }

  return [
    <UTCPeriodSelector
        storeTimeIndex={store.timeIndex}
        initialStartDate={store.timeIndex.startDate}
        initialEndDate={store.timeIndex.endDate}
        onSelect={(from, to) => {
          setDateRange({from, to})
        }}
        granularity={granularity}
        rangeSelection={rangeSelection}
    />,
    dateRange
  ]
}

export function UTCPeriodSelector({onSelect,storeTimeIndex, initialStartDate, initialEndDate, granularity, layout = "horizontal", rangeSelection=false}: {
  initialStartDate: Date,
  initialEndDate?: Date,
  granularity: TimeUnits,
  onSelect: (startDate: Date, endDate: Date) => void,
  storeTimeIndex: TimeIndex,
  layout?: "horizontal" | "vertical",
  rangeSelection?: boolean
})
{
  if(!initialEndDate){
    initialEndDate = getLastDateOfPeriodInUTC(initialStartDate, granularity);
  }
  const serializedStoreTimeIndex = storeTimeIndex.serialize();
  const periodSelectionOptions = useMemo(()=>{
    return storeTimeIndex.withGranularity(granularity).timeLine.map((v)=>{
      return {key: v.fDate, text: v.fDate, value: v.date.getTime()}
    })
  }, [serializedStoreTimeIndex, granularity])
  const [dateRange, setDateRange] = useState<DateRangeType>({from: initialStartDate, to:initialEndDate})

  useEffect(()=>{
    setDateRange({from: getFirstDateOfPeriodInUTC(dateRange.from, granularity), to: getLastDateOfPeriodInUTC(dateRange.to, granularity)})
  }, [granularity]);

  return (
      <div className={`date-range ${layout === "vertical" ? "vertical": ""}`}>
        <div className="date-range-from">
          {rangeSelection && <span className="date-range-label">From</span>}
          <Select options={periodSelectionOptions.filter((v)=>v.value as number <= getFirstDateOfPeriodInUTC(dateRange.to, granularity).getTime())}
                  defaultValue={periodSelectionOptions.filter((v)=>v.value as number === dateRange.from.getTime())?.[0]?.value}
                  value={periodSelectionOptions.filter((v)=>v.value as number === dateRange.from.getTime())?.[0]?.value}
                  onChange={(evt, data)=>{
                    const timeStamp = data.value as number;
                    if(timeStamp){
                      let startDate = new Date(timeStamp);
                      setDateRange((prev)=>({...prev, from: startDate}))
                      onSelect(startDate, getLastDateOfPeriodInUTC(rangeSelection ? dateRange.to : startDate, granularity));
                    }
                  }}
          />
        </div>
        {rangeSelection && <div className="date-range-to">
          <span className="date-range-label">To</span>
          <Select options={periodSelectionOptions.filter((v)=>v.value as number >= dateRange.from.getTime())}
                  defaultValue={periodSelectionOptions.filter((v)=>v.value as number === getFirstDateOfPeriodInUTC(dateRange.to, granularity).getTime())?.[0]?.value}
                  value={periodSelectionOptions.filter((v)=>v.value as number === getFirstDateOfPeriodInUTC(dateRange.to, granularity).getTime())?.[0]?.value}
                  onChange={(evt, data)=>{
                    const timeStamp = data.value as number;
                    if(timeStamp){
                      let endDate = new Date(timeStamp);
                      setDateRange((prev)=>({...prev, to: getLastDateOfPeriodInUTC(endDate, granularity)}))
                      onSelect(dateRange.from, getLastDateOfPeriodInUTC(endDate, granularity));
                    }
                  }}
          />
        </div>}
      </div>
  );
}

function getFirstDateOfPeriodInUTC(date: Date, granularity: TimeUnits){
  let dateInUTC = utcDate(date.getUTCFullYear(),date.getUTCMonth(),date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
  return truncateTime(granularity, dateInUTC);
}

function getLastDateOfTheMonth(date: Date){
   return utcDate(date.getUTCFullYear(), date.getUTCMonth()+1, 0);
}