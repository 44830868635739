import { AmProject } from "../../ps-types";
import { useCompanyId } from "../../core";
import { JobProgress, JobProgressData } from "../JobProgress";
import { getWorkflowLogs } from "./amProjectSync.client";
import { SmallLoading } from "../../ui/Loading";
import { useEffect, useState } from "react";

export interface SpvExecutionLogEntry {
  time: number;
  type: string;
  message?: string;
  level?: string;
  spvId?: string;
  status?: string;
}

export function SPVSyncLogs({
  executionId,
  projects,
  initialJobProgress
}: {
  executionId: string;
  projects: AmProject[];
  initialJobProgress: any;
}) {
  const companyId = useCompanyId();
  const [loading, setLoading] = useState<boolean>(false)
  useEffect(()=>{
    setLoading(true)
  }, [])
  const reloadSpv = async (executionId: string) => {
    let spvLogs = { log: [], status: "" };
    spvLogs = await getWorkflowLogs(companyId, executionId);
    setLoading(false)
    const completedLogsCount = spvLogs.log.filter((log: SpvExecutionLogEntry) => log?.status === "completed").length;
    return {
      logEntries: spvLogs.log || [],
      total: completedLogsCount,
    } as JobProgressData;
  };

  return (
    <>
      {loading && <SmallLoading message="loading logs" />}
      <JobProgress
        projects={projects}
        initialJobProgress={initialJobProgress}
        refreshLogs={() => {
          return reloadSpv(executionId);
        }}
      />
    </>
  );
}
