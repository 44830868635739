import {LineItemsStore} from "../../../ps-models/lineitems-store";
import {LoadingBlock} from "../../../ui/Loading";
  import React, {useEffect, useMemo} from "react";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";
import {useDrop} from "react-dnd";
import {useDashboardService} from "../DashboardConfigService";
import {WidgetWrapper} from "../WidgetWrapper";
import {Button, Checkbox, Grid, Header, Icon, Input, Segment, SemanticWIDTHS} from "semantic-ui-react";
import {groupBy, values} from "ramda";
import {DashboardConfig} from "../DashboardConfig";
import {ClosableSection} from "../../../ClosableSection";
import {useOnConfigChange} from "./commons";
import {EVENTS} from "../EventHandler";
import {useInstantUpdater} from "../../../generic.hooks";

const MAX_COLUMN_SIZE = 16;

interface ColumnProps {
  key: string;
  size: number;
  title?: string;
  collapsible?: boolean;
}
interface ConfigProps {
  columns: Record<string, ColumnProps>
  bordered?:boolean
}

registerWidgetType({
    typeId: 'Container',
    metadata: {
      name: 'Container To Add Elements',
      description: '',
      icon: 'box',
    },
    defaultConfig: {
       columns: {
          '1': {
            key: '1',
            size: MAX_COLUMN_SIZE,
            collapsible: false,
          }
       }
    },
  renderConfig: (config: any, context: BuilderContext, setConfig: (config: ConfigProps) => void) => {
    return <WidgetConfig
      config={config} context={context} onConfigChange={setConfig}/>
  },
    render: (config: any, context: BuilderContext) => {
      return <Widget context={context}/>
    }
  }
)

function cloneConfig(config: ConfigProps) {
  return {
    ...config,
    columns: values(config.columns).reduce((acc, column) => {
      return {...acc, [column.key]: {...column}}
    }
    , {})
  } as ConfigProps
}

function WidgetConfig({config, context, onConfigChange}:
                                      { config: ConfigProps, context: BuilderContext, onConfigChange: (config: ConfigProps) => void }) {
  let store = context.store as LineItemsStore;


  let [localConfig, setLocalConfig] = React.useState(
    cloneConfig(config)
  );

  useEffect(() => {
    setLocalConfig(cloneConfig(config));
  }, [config]);

  const handleApply = () => {
    onConfigChange(localConfig);
  }

  const handleColumnChange = (columnKey: string, column: ColumnProps) => {
    setLocalConfig({...localConfig, columns: {...localConfig.columns, [columnKey]: column}})
  }

  const handleAddColumn = () => {
    // Add Column and equalize sizes
    let columns = Object.values(localConfig.columns);
    let newKey = (columns.length + 1).toString();
    let newColumn: ColumnProps = {key: newKey, size: MAX_COLUMN_SIZE};
    let newColumns = {...localConfig.columns, [newKey]: newColumn};
    let size = Math.floor(MAX_COLUMN_SIZE / (columns.length + 1));
    Object.values(newColumns).forEach(column => column.size = size);
    setLocalConfig({...localConfig, columns: newColumns});
  }

  const handleDeleteColumn = (columnKey: string) => {
     //Delete column without changing sizes
    let newColumns = {...localConfig.columns};
    delete newColumns[columnKey];
    setLocalConfig({...localConfig, columns: newColumns});
  }

  const handleDiscard = () => {
    setLocalConfig(config);
  }

  return <>
    <Button  size="tiny" icon onClick={handleAddColumn}>
      <Icon name='plus' />
    </Button>
    <Checkbox label={'Bordered'}
              checked={localConfig?.bordered}
              toggle={true}
              onChange={(e, data) =>{
                setLocalConfig((prev)=>({...prev, bordered: data.checked}))
              }}
    />
  {Object.values(localConfig.columns).map((column: ColumnProps) => <>
    <Segment key={column.key}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Column #{column.key}
        <Button size="tiny" icon>
          <Icon name='trash' onClick={() => handleDeleteColumn(column.key)}/>
        </Button>
      </div>
      <div>
    <label>Width</label>
    <Input size="mini" value={column.size} onChange={(e, data) =>
      handleColumnChange(column.key, {...column, size: parseInt(data.value)})
    } />
    <label>Title</label>
    <Input size="mini" value={column?.title} onChange={(e, data) =>
        handleColumnChange(column.key, {...column, title: data.value})
    } />
    <Checkbox label={'Collapsible'}
              checked={column?.collapsible}
              toggle={true}
              onChange={(e, data) =>{
                handleColumnChange(column.key, {...column, collapsible: data.checked})
              }}
    />
  </div></Segment>
  </>
  )}
    <p></p>
    <Button size="small" primary onClick={handleApply}>Apply</Button>
    <Button size="small" color={"grey"} onClick={handleDiscard}>Discard</Button>
  </>

}

function getSortIndex(config: any) {
  return config?.containerData?.widgetIndex || 0;
}

function useWidgetChanges(context: BuilderContext) {


  let [,update] = useInstantUpdater()

  useEffect(() => {
    let dsService = context.appContext;

    dsService.handler.addListeners([EVENTS.WIDGET_ADDED, EVENTS.WIDGET_DELETED], (event: any) => {
      let widget = event.data;
      if(widget?.parent === context.id) {
        update();
      }
    });

  }, []);

}

function Widget({context}: {  context: BuilderContext }) {
  let dsService = context.appContext;
  let dsConfig = dsService.getConfig();
  let widgetId: string = context.id;

  let {config} = useOnConfigChange<ConfigProps>(context);
   useWidgetChanges(context);


  let widgetsByColumn = getWidgetsByColumn(dsConfig, widgetId);

  return <>
    <Grid style={{width: '100%',
    ...(config?.bordered ? {
      border: "2px solid",
      margin: '0.1rem 0',
      borderRadius: "0.28571429rem !important"}: {})
       }}>
      <Grid.Row>
    {Object.entries(config.columns).map(([key, column]) => {
       return <Grid.Column key={key} width={column.size as SemanticWIDTHS}>
         {!context.readOnly && <Region config={config} context={context} columnKey={column.key}
                                       widgetIndex={Object.values(widgetsByColumn[column.key] || {}).length} />}
         {column?.title && <Header as="h2" color="purple">{column.title}</Header>}
          {Object.values(widgetsByColumn[column.key] || {}).map((widget) => {
            return <WidgetWrapper key={widget.id} widgetId={widget.id} context={context}  />
          })}
       </Grid.Column>
    })}
      </Grid.Row>
    </Grid>

  </>


}
function Widget2({config, context}: { config: ConfigProps, context: BuilderContext }) {
  let {getService, ready} = useDashboardService();
  let dsService = getService();
  let dsConfig = dsService.getConfig();
  let widgetId: string = context.id;
  let widgetsByColumn = getWidgetsByColumn(dsConfig, widgetId);




  return <>
    <WidgetView widgetsByColumn={widgetsByColumn} config={config} context={context} />
  </>

}

function WidgetView({widgetsByColumn, config, context}:
                      { widgetsByColumn: Record<string, any[]>, config: ConfigProps, context: BuilderContext }) {



  return  <>

  {Object.values(config.columns).map((column: ColumnProps) => {
      return <WidgetColumnView key={column.key}
                               widgetsByColumn={widgetsByColumn} column={column}
                               config={config}
                               context={context} />
    })}
  </>

}

function WidgetColumnView({widgetsByColumn, column, config, context}: {widgetsByColumn: Record<string, any[]>, column: ColumnProps,config: ConfigProps, context: BuilderContext}){



  if(column?.collapsible){
    return <Grid.Column width={column.size as SemanticWIDTHS}>
       <ClosableSection opened={true}
                        title={<><strong style={{fontSize: "16px"}}>
                          {column?.title ?? ""}
                        </strong>
                        </>}
                        level="h3"
       >
        <ColumnRegionAndWidgetsView widgetsByColumn={widgetsByColumn} column={column} config={config} context={context} />
      </ClosableSection>
    </Grid.Column>
  }

  return <Grid.Column width={column.size as SemanticWIDTHS}>
      {column?.title !==undefined && <Header as="h2" color="purple">{column.title}</Header>}
      <ColumnRegionAndWidgetsView widgetsByColumn={widgetsByColumn} column={column} config={config} context={context} />
  </Grid.Column>
}

function ColumnRegionAndWidgetsView({widgetsByColumn, column, config, context}: {widgetsByColumn: Record<string, any[]>, column: ColumnProps,config: ConfigProps, context: BuilderContext}){
  let widgets = widgetsByColumn[column.key] || [];
  let firstIndex =  0;
  if(widgets.length > 0) {
    firstIndex = getSortIndex(widgets[0].config) - 1;
  }

  return <>
    {!context.readOnly &&
      <Region config={config} context={context} columnKey={column.key} widgetIndex={firstIndex} />
     }
  {widgets.map((widget, index) => <>
    <WidgetWrapper key={widget.id} widgetId={widget.id} context={context}  />
  </>)}</>
}
function getWidgetsByColumn(dsConfig: DashboardConfig, widgetId: string) {
  let sortedWidgets = dsConfig.getByParentId(widgetId)
    .sort((a, b) =>
      getSortIndex(a.config) - getSortIndex(b.config));

  let widgetsByColumn = groupBy((widget) =>
    widget.config?.containerData?.columnKey || '1', sortedWidgets);
  return widgetsByColumn;
}


function Region({config, columnKey, context, widgetIndex}: { config: ConfigProps, context: BuilderContext, columnKey: string, widgetIndex: number }) {
  let widgetId: string = context.id;

  let {lastUpdate} = useDashboardService();


  const [{ canDrop, isOver, isOverCurrent }, drop] = useDrop(() => ({
    accept: 'box',
    // drop: () => ({ widgetId, columnKey }),
    drop(_item: unknown, monitor) {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }
      // setHasDropped(true)
      // setHasDroppedOnChild(didDrop)
      return { widgetId, columnKey, widgetIndex, lastUpdate }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  }), [widgetId, columnKey, widgetIndex])



  const isActive = canDrop && isOverCurrent

  let style = {border: "1px dashed #ccc", padding: "3px", };

  if (isActive) {
    style = {...style, border: '2px dashed green'}
  }

  return <div ref={drop} style={style} ></div>
}