import {ProjectToSync} from "../../ps-types";
import psPlatformClient from "../../psPlatformClient";


export const getProjectsToSync = async (companyId: string): Promise<ProjectToSync[]> => {
  const result = await psPlatformClient<{companyId: string}, ProjectToSync[]>(
    {method: 'get', url: `/am/${companyId}/project/sync`, data: {companyId}})
  return result.data;
}

//POST  `/am/${companyId}/project/sync' body: projectIds
export const syncProjects = async (companyId: string, projectIds: string[], data: Record<string, any> = {}) => {
  const result = await psPlatformClient<{companyId: string, projectIds: string[]}, null>(
    {method: 'post', url: `/am/${companyId}/project/sync`, data: {companyId, projectIds, ...data}})
  return result.data;
}

export const syncExecutionProjects = async (companyId: string) => {
  const result = await psPlatformClient<null, null>(
    {method: 'post', url: `/am/${companyId}/project-sync`})
  return result.data;
}

export const syncActuals = async (companyId: string, projectIds: string[], action: string = "", data: Record<string, any> = {}) => {
  const result = await psPlatformClient<{companyId: string, projectIds: string[]}, null>(
    {method: 'post', url: `/am/${companyId}/project/sync/${action}/workflow`, data: {companyId, projectIds, ...data}})
  return result.data;
}

export const importSpvsWorkflowCall = async (companyId: string, spvsIds: string[], action: string = "", data: Record<string, any> = {}) => {
  const result = await psPlatformClient<{companyId: string, spvsIds: string[]}, {executionId: string}>(
    {method: 'post', url: `/am/${companyId}/spvs-sync-workflow-call/${action}`, data: {companyId, spvsIds, ...data}})
  return result.data;
}

export const getAllSpvs = async (companyId: string) => {
  const result = await psPlatformClient<{companyId: string}, string[]>(
    {method: 'get', url: `/am/${companyId}/project/sync/spvs`})
  return result.data;
}

export const getWorkflowLogs = async (companyId: string, executionId: string) => {
  const result = await psPlatformClient<{companyId: string, executionId: string}, any>(
    {method: 'get', url: `/am/${companyId}/workflow-log/${executionId}`})
  return result.data;
}

export const getAllWorkflowLogsByType = async (companyId: string, type: string) => {
  const result = await psPlatformClient<{companyId: string, type: string}, any>(
    {method: 'get', url: `/am/${companyId}/all-workflow-log/${type}`})
  return result.data;
}