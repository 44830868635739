import { Button, Grid, Radio, SemanticCOLORS } from "semantic-ui-react";
import { DeleteButton } from "../../ui/crud/DeleteButton.component";
import { usePopup } from "../../ui/popup/Popup";
import { amDeleteScenario, getScenarios } from "./scenarios.client";
import { EntityCrud, buildEntityDef } from "../../ui/crud/EntityCrud.component";
import { ContentPopup } from "../../ui/popup/ContentPopup";

function DeleteBtn({
  entityName,
  entity,
  handleDelete,
}: {
  entityName: string;
  entity: any;
  handleDelete: (entity: any) => void;
}) {
  const content = `Are you sure you want to delete the ${entityName} '${entity["name"]}'?`;
  return (
    <DeleteButton content={content} onDelete={() => handleDelete(entity)} />
  );
}

function OnClickRow({
  scenario,
  setSelectedScenario,
  closePopup,
  selectedScenarioId,
}: {
  scenario: any;
  closePopup: () => void;
  setSelectedScenario: (scenario: string) => void;
  selectedScenarioId: string;
}) {
  return (
    <>
      <Radio
        checked={scenario.id == selectedScenarioId}
        label={scenario.name}
        onClick={(e, data) => {
          setSelectedScenario(scenario.id as string);
          closePopup();
        }}
      />
    </>
  );
}

export function LoadScenarios({
  setSelectedScenario,
  text = "Load Scenario",
  buttonColor,
  styles,
  companyId,
  selectedScenario,
  update,
}: {
  setSelectedScenario: (scenario: string) => void;
  text?: string;
  buttonColor?: SemanticCOLORS;
  styles?: any;
  companyId: string;
  selectedScenario: string;
  update: () => void;
}) {
  let { openPopup, closePopup } = usePopup();

  const getEntities = () => getScenarios(companyId);
  const deleteEntity = async function(scenario: any){
    await amDeleteScenario(companyId, scenario.id)
    update();
  }
  let def = buildEntityDef(
    {
      entityName: "Scenarios",
      title: "",
      getEntities,
      deleteEntity,
      table: {
        tableComponent: "dataGrid",
        deleteButton: (props) => <DeleteBtn {...props} />,
        actionColumnTitle: "Delete",
        disableColumnFilter: true,
        disableColumnSelector: true,
        disableDensitySelector: true,
        disableToolbarButton: true,
        selectedRow: selectedScenario,
        actionColumnWidth: 80,
        entityColumnWidth: 400,
        defaultPageSize: 5
      },
    },
    {
      name: {
        title: "Name",
        create: { type: "hidden" },
        table: {
          render: (_value: string, entity) => (
            <OnClickRow
              scenario={entity}
              setSelectedScenario={setSelectedScenario}
              closePopup={closePopup}
              selectedScenarioId={selectedScenario}
            />
          ),
        },
      },
    }
  );

  let optionsPopup = (
    <ContentPopup header={"Select Scenario"} onClose={closePopup}>
      <>
        <Grid.Row>
          <EntityCrud entityDef={def} />
        </Grid.Row>
      </>
    </ContentPopup>
  );
  return (
    <>
      <Button
        color={buttonColor}
        size="tiny"
        onClick={() => openPopup(optionsPopup)}
        style={styles}
      >
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </Button>
    </>
  );
}
