import {LineItemsStore, QueryResult, ResultOptions, StoreQuery} from "../../../ps-models/lineitems-store";
import {
  Button,
  Checkbox,
  DropdownProps,
  Header,
  Input,
  Segment,
  SegmentInline,
  Select,
  TextArea
} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {LineItemsTableProps, LineItemsTableWithFormulas} from "../../../lineitems-store/LineItemsTableView";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";
import {StoreQueryFlat, StoreQueryFlatDto} from "../../../ps-models/lineitems-store/StoreQueryFlat";
import {QueryEditor} from "../StoreQueryEditor";
import {TimeIndexEditorStandalone} from "../../line-items-editor/TimeIndexEditor";
import {addTime, buildTimeIndex} from "../../../ps-models";
import {
  getConfig, getGlobalContext, getStore,
  useOnConfigChange,
  useOnStoreReady,
  useUpdateOnGlobalContextChange,
} from "./commons";
import {StoreFieldSelector} from "../../../lineitems-store/StoreFieldSelector";
import {SideBySideView} from "../../SideBySideView";
import {MultiSelect} from "../../../ui/MultiSelect";
import {DateRangeType} from "../../../ps-types";
import {
  getDatesFromTimeLineConfig,
  TimeLineConfigurations,
  TimeLineConfigurator
} from "../common-components/TimeLineConfigurator";

interface ConfigProps {
  title: string;
  groupBy?: string,
  sectionsBy?: string[],
  itemsPerPage?: LineItemsTableProps['pageSize'];
  highlightNonZeroCells?: LineItemsTableProps['highlightNonZeroCells'];
  storeQueryFlat?: StoreQueryFlatDto,
  lineItemStyles?: Record<string, string>,
  table?: ResultOptions['table'],
  hiddenLineItemNames?: string[],
  trimmingSpecification?: string[],
  timeLineConfig?: TimeLineConfigurations
}
// @TODO: Make this cleaner, better to have some other type extending ConfigProps and over-riding storeQueryFlat
interface DeserializedConfigProps {
  title: string;
  groupBy?: string,
  sectionsBy?: string[],
  itemsPerPage?: LineItemsTableProps['pageSize'];
  highlightNonZeroCells?: LineItemsTableProps['highlightNonZeroCells'];
  storeQueryFlat?: StoreQueryFlat,
  lineItemStyles?: Record<string, string>
  table?: ResultOptions['table'],
  hiddenLineItemNames?: string[]
  trimmingSpecification?: ConfigProps['trimmingSpecification']
  timeLineConfig?: TimeLineConfigurations
}

const buildTrimmingOption = (opt: string)=>{
  return {key: opt.toLowerCase().replace(/\s+/g, ''), value: opt.toLowerCase().replace(/\s+/g, ''), text: opt}
}

const TRIMMING_OPTIONS = [
  "Trim Leading Columns With Zero Value",
  "Trim Rows With Zero Value",
  "Align Leading Columns",
].map((opt)=>buildTrimmingOption(opt))

const DEFAULT_STORE_QUERY_FLAT = new StoreQueryFlat();

registerWidgetType({
    typeId: 'LineItemsTable',
    metadata: {
      name: 'Time Series and Parameters Line Item Table',
      description: '',
      icon: 'table',
    },
    defaultConfig: {
      title: '',
      groupBy: 'fields.cname',
      storeQueryFlat: DEFAULT_STORE_QUERY_FLAT.serialize(),
    } as ConfigProps,
  renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
    return <LineItemsTableWidgetConfig
      config={config} context={context} onConfigChange={setConfig}/>
  },
    render: (config: any, context: BuilderContext, setOutput) => {
      return <LineItemsTableWidgetWithCompare context={context} setOutput={setOutput}/>
    },
  preRender: (context) => {
      twoPhaseResultsProcessing(context, 'calculating')
  }
  }
)



const deserializeConfig = (config: ConfigProps): DeserializedConfigProps  =>{
  return {...config, ...(config.storeQueryFlat ? {storeQueryFlat: StoreQueryFlat.deserialize(config?.storeQueryFlat)} :{})} as DeserializedConfigProps;
}

export function LineItemsTableWidgetConfig({config, context, onConfigChange, }:
                                      { config: ConfigProps, context: BuilderContext, onConfigChange: (config: any) => void  }) {
  let store = context.store;
  let [localConfig, setLocalConfig] = React.useState<DeserializedConfigProps>(deserializeConfig(config));

  let [customizeWidgetTimeIndex, setCustomizeWidgetTimeIndex] = useState<boolean>(!!localConfig.storeQueryFlat?.timeIndex);
  let [customizeTableConfig, setCustomizeTableConfig] = useState<boolean>(!!localConfig.table);

  useEffect(() => {
    setLocalConfig(deserializeConfig(config));
  }, [config]);


  let options = [
    {key: 'None', text: 'None', value: 'None'},
    {key: 'Name', text: 'Name', value: 'fields.cname'},
    {key: 'Label', text: 'Label', value: 'store_sourceLabel'},
    {key: 'DataSet Name', text: 'Data Set Name', value: 'store_sourceName'},
  ]

  const handleApply = () => {
    const serializedConfig = {...localConfig, ...(localConfig.storeQueryFlat ? {storeQueryFlat: localConfig.storeQueryFlat.serialize()} : {})}
    onConfigChange(serializedConfig);
  }

  const handleDiscard = () => {
    setLocalConfig(deserializeConfig(config));
  }

  return <>
    Grouped by <Select
    value={localConfig.groupBy}
    options={options}
    onChange={(e, data) => {
      setLocalConfig({...localConfig, groupBy: data.value as string})
    }}
  />
    <Segment>
      <SegmentInline>
        Title: <Input value={localConfig.title} onChange={(e, data) => {
          setLocalConfig({...localConfig, title: data.value})
        }} />
        Sections By: <Input value={localConfig.sectionsBy} onChange={(e, data) => {
        setLocalConfig({...localConfig, sectionsBy: [data.value]})
      }} />
        {/* This does not take effect immediately and needs a page refresh, which is fine because this will only happen in builder. */}
        Rows To Show Per Page (Min: 1; Max 100): <Input type={"number"} value={localConfig?.itemsPerPage}
                                       min={1}
                                       max={100}
                                       onChange={(e, data) => {
        let numericValue: number | undefined = data.value === "0" ? undefined: +(data.value);
        setLocalConfig((prev)=>({...prev, itemsPerPage: numericValue}))
      }} />
        <Checkbox toggle checked={localConfig.highlightNonZeroCells ?? false}
          onChange={(e, data) => {
              setLocalConfig((prev) => {
                return {...prev, highlightNonZeroCells: data.checked};
              })
          }}
        label={"Highlight Non Zero Cells"} />
      <QueryEditor capabilities={["names", "fields"]} store={store} query={localConfig.storeQueryFlat ?? DEFAULT_STORE_QUERY_FLAT} onQueryUpdate={(updatedQuery)=>{
        let query = updatedQuery;
        if(localConfig.storeQueryFlat && localConfig.storeQueryFlat.timeIndex){
          query = updatedQuery.withTimeIndex(localConfig.storeQueryFlat.timeIndex)
        }
        setLocalConfig({...localConfig, storeQueryFlat: query })
      }} />
        <Checkbox toggle checked={customizeWidgetTimeIndex}
                  onChange={(e, data) => {
                    if(!!data.checked === false){
                      setLocalConfig((prev) => {
                        const serializedExistingStoreQueryFlat = (prev.storeQueryFlat ?? DEFAULT_STORE_QUERY_FLAT)?.serialize();
                        if(serializedExistingStoreQueryFlat){
                          const {timeIndex, ...storeQueryFlatSerializedWithStrippedTimeIndex} = serializedExistingStoreQueryFlat;
                          let storeQueryFlatToSet = StoreQueryFlat.deserialize(storeQueryFlatSerializedWithStrippedTimeIndex)
                          return {...prev, storeQueryFlat: storeQueryFlatToSet }
                        }
                        return {...prev};
                      })
                    }
                    setCustomizeWidgetTimeIndex(!!data.checked)
                  }}
                  label={"Customize Widget Time Index"} />
        {customizeWidgetTimeIndex && <TimeIndexEditorStandalone initiallySelectedDates={{startDate: localConfig.storeQueryFlat?.timeIndex ? localConfig.storeQueryFlat?.timeIndex.startDate : store.timeIndex.startDate, endDate: localConfig.storeQueryFlat?.timeIndex ? localConfig.storeQueryFlat?.timeIndex.endDate : store.timeIndex.endDate}}
               initialGranularity={localConfig.storeQueryFlat?.timeIndex ? localConfig.storeQueryFlat?.timeIndex.getUnit() : store.timeIndex.getUnit()}
               onUpdate={(updatedTimeIndex)=>{
                 if(JSON.stringify(store.timeIndex.serialize()) !== JSON.stringify(updatedTimeIndex.serialize())){
                   setLocalConfig((prev)=>(
                       {...prev, storeQueryFlat: (prev.storeQueryFlat ?? DEFAULT_STORE_QUERY_FLAT).withTimeIndex(updatedTimeIndex) }))
                 }
               }}
              layout={"vertical"}
      />}
        <TimeLineConfigurator store={store} timeLineConfig={localConfig?.timeLineConfig} onTimeLineConfigChange={(updatedTimeLineConfig)=>{
          setLocalConfig((prev)=>({...prev, timeLineConfig: updatedTimeLineConfig}))
        }} />
        <Segment>
          <h5>Table Configurations</h5>
          <Checkbox toggle checked={customizeTableConfig}
                    onChange={(e, data) => {
                      if(!!data.checked === false){
                        setLocalConfig((prev)=>{
                          const {table, ...restConfig} = prev;
                          return restConfig;
                        })
                      }
                      setCustomizeTableConfig(!!data.checked);
                    }}
                    label={"Customize Table Configuration"} />
          {customizeTableConfig && <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '2px', margin: '10px 0px'}}>Row Dimension: <StoreFieldSelector store={store}
            value={localConfig?.table?.rowDimensionFields}
            multiple
            onChange={(e:any, data:DropdownProps) => setLocalConfig({...localConfig, table: {rowDimensionFields: data.value as string[]}}) }
          />
            <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', alignItems: 'flex-start', gap: '5px',
              border: '1px dotted gray', width:'35%',padding: '5px 5px', marginBottom: '10px'
            }}>
              <strong>Aggregate Column Configuration</strong>
            <Checkbox toggle checked={localConfig?.table?.aggregateColumnConfig !==undefined}
                      onChange={(e, data) => {
                        if(!!data.checked === false){
                          setLocalConfig((prev) => {
                            if(prev?.table?.aggregateColumnConfig) {
                              let { aggregateColumnConfig, ...restTableConfig } = prev?.table;
                              return { ...prev, ...(restTableConfig !==undefined ? { table: restTableConfig } : {} )}
                            }
                            return prev;
                          })
                        } else {
                          setLocalConfig((prev)=>({...prev, table: {...prev?.table, aggregateColumnConfig: {} }}))
                        }
                      }}
            label={"Include Column"} />
            {localConfig?.table?.aggregateColumnConfig && <ItemsExcludedFromAggregateColumnEditor localConfig={localConfig} setLocalConfig={setLocalConfig} />}
            </div>
            <div>
              <strong>Trimming Specification:</strong><br/>
               <MultiSelect
                options={TRIMMING_OPTIONS}
                onChanged={(selectedOptions) => {
                  setLocalConfig((prev) => {
                    if(selectedOptions.length ===0){
                      const {trimmingSpecification, ...restConfig} = prev;
                      return restConfig;
                    } else {
                      return {...prev, trimmingSpecification: selectedOptions.map((selection)=>selection.value as string)}
                    }
                  })
                }}
                hideSelectAll
                showSelectAllBtn
                showUnSelectAllBtn
                initialSelection={localConfig.trimmingSpecification?.map(buildTrimmingOption)}
                hideSelectByGroup
            />
            </div>
          </div>}
          <StylesEditor localConfig={localConfig} setLocalConfig={setLocalConfig} />
          <HiddenLineItemsEditor localConfig={localConfig} setLocalConfig={setLocalConfig} />
        </Segment>
      </SegmentInline>
    </Segment>
    <Button primary onClick={handleApply}>Apply</Button>
    <Button color={"grey"} onClick={handleDiscard}>Discard</Button>
  </>

}




export function StylesEditor({localConfig, setLocalConfig}: {localConfig: DeserializedConfigProps, setLocalConfig: (config: DeserializedConfigProps) => void}){

  function getLineItemsWithStyle(style: string) {
    return Object.entries(localConfig.lineItemStyles || {})
      .filter(([liName, st]) => st === style)
      .map(([liName, style]) => liName).join("\n");
  }

  let [headers, setHeaders] = React.useState<string>("")
  let [sectionClosing, setSectionClosing] = React.useState<string>("");

  useEffect(() => {
    setSectionClosing(getLineItemsWithStyle('section-closing'));
    setHeaders(getLineItemsWithStyle('header'));

  }, [localConfig.lineItemStyles]);

  const handleApply = (style: string, liText: string) => {
     //Set headers
    let styles: Record<string, string> = {}

     headers.split("\n").forEach((liName) => {
       styles[liName] = 'header';
     });

    sectionClosing.split("\n").forEach((liName) => {
      styles[liName] = 'section-closing';
    });

    setLocalConfig({...localConfig, lineItemStyles: styles});
  }

  return <div>
    {/** TODO: Make a nicer component **/}
    <strong>Header</strong><br/>
    <TextArea
      style={{width: "100%"}}
      value={headers}
      onBlur={() => handleApply('header', headers)}
      onChange={(e, data) => {
        setHeaders(data.value as string);
      }}
    />
    <div>
      <strong>Section Closing Highlight</strong><br/>
    <TextArea
      style={{width: "100%"}}
      value={sectionClosing}
      onBlur={() => handleApply('section-closing', sectionClosing)}
      onChange={(e, data) => {
        setSectionClosing(data.value as string);
      }}
    />
    </div>
  </div>
}

export function HiddenLineItemsEditor({localConfig, setLocalConfig}: {localConfig: DeserializedConfigProps, setLocalConfig: (config: DeserializedConfigProps) => void}){
  let [hiddenLineItems, setHiddenLineItems] = React.useState<string>("")

  useEffect(() => {
    setHiddenLineItems((localConfig.hiddenLineItemNames || []).join("\n"));
  }, [localConfig.hiddenLineItemNames]);

  const handleApply = () => {
    setLocalConfig({...localConfig, hiddenLineItemNames: hiddenLineItems.split("\n")});
  }

  return <div>
    {/** TODO: Make a nicer component **/}
    <strong>Hidden Line Items</strong><br/>
    <TextArea
        style={{width: "100%"}}
        value={hiddenLineItems}
        onBlur={() => handleApply()}
        onChange={(e, data) => {
          setHiddenLineItems(data.value as string);
        }}
    />
  </div>
}

// @TODO: We will unify all these lineItemsEditor into a single component.
function ItemsExcludedFromAggregateColumnEditor({localConfig, setLocalConfig}: {localConfig: DeserializedConfigProps, setLocalConfig: React.Dispatch<React.SetStateAction<DeserializedConfigProps>>}){
  let [chosenLineItems, setChosenLineItems] = React.useState<string>("")

  useEffect(() => {
    setChosenLineItems((localConfig.table?.aggregateColumnConfig?.excludedItems || []).join("\n"));
  }, [localConfig.table?.aggregateColumnConfig?.excludedItems]);

  const handleApply = () => {
    setLocalConfig((prev)=>({...prev, table: {...prev.table, aggregateColumnConfig: {excludedItems: chosenLineItems.split("\n")}}}))
  }

  return <div>
    {/** TODO: Make a nicer component **/}
    <strong>Excluded Line Items</strong><br/>
    <TextArea
        style={{width: "100%"}}
        value={chosenLineItems}
        onBlur={() => handleApply()}
        onChange={(e, data) => {
          setChosenLineItems(data.value as string);
        }}
    />
  </div>
}

function LineItemsTableWidgetWithCompare({context, setOutput}: {context: BuilderContext, setOutput:(key: string, value: any) => void }) {
  useOnStoreReady(context);
  useUpdateOnGlobalContextChange(context);

  const storeToCompareWith = context.appContext.getLastStoreToCompareWith();
  const mainStore = context.appContext.getStore();


  if(storeToCompareWith){
    return <SideBySideView selectedStores={mainStore}
                           compareWithStores={storeToCompareWith}
                           highlightComparedWithStore={true}
                           render={(store) =>
                             <LineItemsTableWidget  context={{...context, store}}/>}
    />
  }
  return <LineItemsTableWidget context={{...context, store: mainStore}} />
}


function applyResultConfigs(result:QueryResult, config: ConfigProps, context: BuilderContext, phase: 'calculating' | 'rendering' = 'rendering') {

  let tableProps: LineItemsTableProps = {
    queryResult: result,
  }

  if(config.hiddenLineItemNames && config.hiddenLineItemNames.length>0){
    result.hideByCNames(config.hiddenLineItemNames)
  }

  if(config.trimmingSpecification?.includes("trimleadingcolumnswithzerovalue")){ // Make sure to call this before adding sections, so we don't check values for section header rows
    result.trimLeadingColumnsWithZeroValue();
  }

  if(config.trimmingSpecification?.includes("trimrowswithzerovalue")){ // Make sure to call this before adding sections, so we don't check values for section header rows
    result.trimRowsWithZeroValue();
  }

  if(phase === 'calculating' && config.trimmingSpecification?.includes("alignleadingcolumns")){ // Make sure to call this before adding sections, so we don't check values for section header rows
    result.trimLeadingColumnsWithZeroValue();
    context.appContext.setOutput(context.id,"queryResultData", result.data);

    let minResultsDate = result.firstTime();

    let minGlobalResultsDate = context.appContext.getGlobalProp('minResultDate');

    if(minResultsDate && (!minGlobalResultsDate || minResultsDate < minGlobalResultsDate)){
      context.appContext.setGlobalProp('minResultDate', minResultsDate)
    }
  }

  if(config.sectionsBy){
    // @TODO: Spread, instead of supplying params one by one
    result.sectionsBy(config.sectionsBy[0], config.sectionsBy[1] ?? "");
  }

  if(config.groupBy) {
    result.collapsibleBy(config.groupBy);
    tableProps.withGroups = true;
  }

  if(config.lineItemStyles) {
    Object.entries(config.lineItemStyles).forEach(([liName, style]) => {
      result.setFormatting(liName, style)
    });
  }

  if(config?.itemsPerPage) {
    tableProps.pageSize = config.itemsPerPage;
  }

  if(config.highlightNonZeroCells !== undefined){
    tableProps.highlightNonZeroCells = config.highlightNonZeroCells
  }

  return tableProps;

}

function getQueryFromConfig(config: ConfigProps) {
  if(config.storeQueryFlat && JSON.stringify(config.storeQueryFlat) !== JSON.stringify(DEFAULT_STORE_QUERY_FLAT.serialize())) {
    let deserializedSQF = StoreQueryFlat.deserialize(config.storeQueryFlat);
    return  deserializedSQF.toStoreQuery(true)
  }
}

function getBaseQuery(config: ConfigProps, store: LineItemsStore) {
  let query = StoreQuery.all();
  let timeIndex = store.timeIndex;

  let queryFromConfig = getQueryFromConfig(config);

  if(queryFromConfig){
    query = queryFromConfig;
    if(queryFromConfig.timeIndex){
      timeIndex = queryFromConfig.timeIndex;
    }
  }

  return {query, timeIndex}
}

function getQueryOptions(config: ConfigProps, store: LineItemsStore) {
  return {
    sortedNames: config.storeQueryFlat?.lineItems,
    ...(config.table ? {table: config.table}: {})
  }
}

/**
 * We need a twoPhase results processing to handle the case where we need to calculate the global minimal date to align the timelines of the TimeSeriesTables
 * The complexity here is that the "minFirstDate" is affected by the specific configuration of the Table (I.E What line items are being shown etc)
 * The first phase: 'calculating' happens on 'PreRender' at the dashboard level (So it will be triggering after filtering or when loading a new store)
 *  During this phase, the minimal date is calculated and stored in the global context.
 * The second phase: 'rendering' happens onRender (At the widget level, just while the widget is being rendered)
 * @param context
 * @param phase
 * @param skipDateBasedConfigApplication
 */
export function twoPhaseResultsProcessing(context: BuilderContext, phase: 'calculating' | 'rendering', skipDateBasedConfigApplication=false) {

  console.time("PROF: LineItemsTableWidget")

  let config = getConfig(context) as ConfigProps;

  if(phase === 'calculating' && !config.trimmingSpecification?.includes("alignleadingcolumns")){
    //We don't need to calculate the minimum if we are not aligning leading columns
    console.timeEnd("PROF: LineItemsTableWidget")
    return
  }


  let store = getStore(context);
  let globalContext = getGlobalContext(context);

  let {query, timeIndex} = getBaseQuery(config, store);

  if(!skipDateBasedConfigApplication){
    if(globalContext.utcDateRange) {
      const dateRange: DateRangeType =  globalContext.utcDateRange;
      const from = dateRange.from || timeIndex.startDate;
      const to = dateRange.to || timeIndex.endDate;
      timeIndex = timeIndex.withDates(from, to)
      query = query.withTimeIndex(timeIndex)
    }
  }

  if(globalContext.granularity) {
    timeIndex = timeIndex.withGranularity(globalContext.granularity)
    query = query
        .withTimeIndex(timeIndex);
  }

  // okay so the align leading columns stuff needs to be done
  if(config?.timeLineConfig){ // This config is supposed to impact the exports too.
    if(config?.timeLineConfig?.granularity) {
      timeIndex = timeIndex.withGranularity(config?.timeLineConfig?.granularity);
      query = query.withTimeIndex(timeIndex);
    }
    const {startDate, endDate} = getDatesFromTimeLineConfig(store, config?.timeLineConfig);
    if(startDate !==null){
      timeIndex = timeIndex.withStartDate(startDate);
      query = query.withTimeIndex(timeIndex);
    }
    if(endDate !==null){
      timeIndex = timeIndex.withEndDate(endDate);
      query = query.withTimeIndex(timeIndex);
    }
  }

  if(!skipDateBasedConfigApplication){
    //The minDate alignment have priority over the dateRange
    if(phase === 'rendering' && config.trimmingSpecification?.includes("alignleadingcolumns")) {
      let minDate = context.appContext.getGlobalProp('minResultDate');
      if(minDate){
        timeIndex = timeIndex.withDates(new Date(minDate), timeIndex.endDate)
        query = query.withTimeIndex(timeIndex)
      }
    }
  }

  let result = store.query(query, getQueryOptions(config, store));


  let tableProps = applyResultConfigs(result, config, context, phase);


  console.timeEnd("PROF: LineItemsTableWidget")

  return {
    store,
    tableProps
  }
}

export function LineItemsTableWidget({context}: {context: BuilderContext}) {
  useOnConfigChange<ConfigProps>(context);

  let config = getConfig(context) as ConfigProps;

  let { store, tableProps} = twoPhaseResultsProcessing(context, 'rendering')!;

  const handlePrint = () => {
    let { tableProps: tablePropsWithoutDateBasedTrimming } = twoPhaseResultsProcessing(context, 'rendering', true)!;

    let resultToPrint = tablePropsWithoutDateBasedTrimming.queryResult;

    let csv = resultToPrint.toCsv();
    //Force Download
    const blob = new Blob([csv], {type: "text/csv"});
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    let now = new Date()
    let fDate = `${now.getFullYear()}-${now.getMonth()}-${now.getDate()}`;
    a.download = `export-${fDate}.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  tableProps.toolbar = {
    exportCallback: handlePrint
  }

  let table =  <LineItemsTableWithFormulas
    store={store}
    {...tableProps}
  />

  return <>
    <Header as="h2" color="purple">{config.title}</Header>
    {table}
  </>
}