import { useRef } from "react";
import { Button, Icon, Progress } from "semantic-ui-react";
import { FileUploader } from "react-drag-drop-files";
import { usePopup } from "./popup/Popup";
import { usePlatformUrls } from "../core";
import { showMappingErrors } from "../assets-management/projects/ProjectTemplate.page";

export interface FileUploadStatus {
  file: File;
  progress: number;
  errors: string[];
  success: boolean;
  warnings: string[];
  projectId?: string;
}
const MultiFileUpload = ({
  uploadFile,
  setFiles,
  files,
  uploadProgress,
  fileTypes,
  successButtonTitle
}: {
  files: FileUploadStatus[];
  setFiles: (arr: FileUploadStatus[]) => void;
  uploadFile: (fileStatus: FileUploadStatus, batchSize: number) => void;
  uploadProgress: number;
  fileTypes: string[],
  successButtonTitle: string
}) => {
  const { openPopup, closePopup } = usePopup();
  const { amProjectDetails } = usePlatformUrls();
  const handleFileSelection = (file: File) => {
    const selectedFiles: any = file;
    if (selectedFiles) {
      const fileArray: Array<any> = Array.from(selectedFiles).map((file) => ({
        file,
        progress: 0,
        errors: [],
        success: false,
        warnings: [],
      }));
      setFiles(fileArray);
    }
  };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const startUpload = () => {
    files.forEach((fileStatus) => {
      if (
        !fileStatus.success &&
        !fileStatus.errors.length &&
        !fileStatus.warnings.length
      ) {
        uploadFile(fileStatus, files.length);
      }
    });
  };

  const clearUpload = () => {
    setFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      <div style={{ margin: "20px", display: "flex" }}>
        <Button floated="right" onClick={startUpload}>
          Start Upload
        </Button>
        <Button floated="right" onClick={clearUpload}>
          Clear
        </Button>
      </div>
      <div
        className="psFileUploader"
        style={{ display: "flex", flexDirection: "column", gap: "15px" }}
      >
        <FileUploader
          handleChange={handleFileSelection}
          name="file"
          types={fileTypes}
          multiple
        >
          <Icon name="file alternate outline" /> <u>Select file</u> or drag and
          drop here
        </FileUploader>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {files.map((fileStatus) => (
            <div key={fileStatus.file.name}>
              <span>
                {fileStatus.file.name} (
                {Math.round(fileStatus.file.size / 1024)} KB)
              </span>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <div style={{ width: "60%", height: "20px" }}>
                  <Progress
                    percent={uploadProgress}
                    autoSuccess
                    className="bar"
                    progress="percent"
                  />
                </div>

                {fileStatus.success && (
                  <>
                    <div>
                      <span>✔️</span>
                    </div>
                    <a
                      href={amProjectDetails(fileStatus?.projectId || "")}
                      target="_blank"
                    >
                      <Button>{successButtonTitle}</Button>
                    </a>
                  </>
                )}
                {fileStatus.errors.length > 0 || fileStatus.warnings.length > 0 ? (
                  <>
                  {fileStatus.errors.length > 0 &&
                    <div>
                      <span>❌</span>
                    </div>}
                    <Button
                    negative={fileStatus.errors.length > 0}
                    color={fileStatus.warnings.length > 0 ? 'orange' : "red"}
                      onClick={() =>
                        showMappingErrors(fileStatus, openPopup, closePopup)
                      }
                    >
                    See errors/warnings
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MultiFileUpload;
