import {z} from 'zod';
import {ProjectVersion} from './AMProject';
import {ProjectLogEntry, ProjectVersionExecutionLogEntry} from "./ProjectLogEntry";

export const WhatIfScenarioInputDef = z.object({
  valueKey: z.string(),
  //Value is (string | number)[][]
  value: z.number().or(z.string()).or(z.boolean()).array().array(),});

export type WhatIfScenarioInput = z.infer<typeof WhatIfScenarioInputDef>;

export const WhatIfScenarioInputValuesDef = z.record(z.string(), WhatIfScenarioInputDef);
export type WhatIfScenarioInputValues = z.infer<typeof WhatIfScenarioInputValuesDef>;




export const CreateWhatIfScenarioRequestDef = z.object({
  type: z.enum(["global", "project-version", "new-projects"]),
  configurationName: z.string(),
  scenarioName: z.string(),
  projectVersions: z.any().array(),
  globalInputs: WhatIfScenarioInputValuesDef.optional().nullable(),
  projectVersionInputs: z.record(z.string(), WhatIfScenarioInputValuesDef).optional().nullable(),
});
export type CreateWhatIfScenarioRequest = z.infer<typeof CreateWhatIfScenarioRequestDef>;

export const UpdateWhatIfScenarioRequestDef = z.object({
  scenarioName: z.string(),
  projectVersions: z.any().array(),
  globalInputs: z.record(z.string(), WhatIfScenarioInputDef).optional().nullable(),
  projectVersionInputs: z.record(z.string(), z.record(z.string(), WhatIfScenarioInputDef)).optional().nullable()
});
export type UpdateWhatIfScenarioRequest = z.infer<typeof UpdateWhatIfScenarioRequestDef>;


export type WhatIfScenarioValuesPerProject = Record<string, WhatIfScenarioInputValues>;

export interface WhatIsScenarioRecord {
  companyId: string,
  scenarioId: string,
  scenarioName: string,
  type: 'global' | 'project-version' | 'new-projects',
  configurationName: string,
  inputData: {
    projectVersions: ProjectVersion[],
    globalInputs?: WhatIfScenarioInputValues
    projectVersionInputs?:WhatIfScenarioValuesPerProject
  }
  status: "new" | "started"  | "completed" | "failed" | "partially-completed"
  log?: ProjectLogEntry[]
}

export type ListWhatIfScenariosResponse = Omit<WhatIsScenarioRecord, "inputData" | "log">[];

export const isFinalStateOfScenario = (status: WhatIsScenarioRecord['status']) => {
  return ["completed", "failed", "partially-completed"].includes(status);
}

export const isFinalStateOfProjectVersion = (status: ProjectVersionExecutionLogEntry['status']) => {
  return ["completed", "failed"].includes(status);
}
